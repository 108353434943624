import React, { useEffect, useRef } from 'react';
import 'react-responsive-modal/styles.css';
import { translate } from '../../assets/js/functions';
import { Link } from 'react-router-dom'
import Modal from "react-responsive-modal";
import ReactPlayer from "react-player";
import { getCurrentLanguage } from '../../assets/js/functions';

export default function VideoModal(props) {

    const { open, toggleModal } = props;
    const reactPlayerRef = useRef(null);

    const closeIcon = (
        <svg width="57" height="57" xmlns="http://www.w3.org/2000/svg" focusable="false"><path d="M43.747 41.287 30.834 28.375l12.913-12.912-2.46-2.46-12.912 12.913-12.912-12.913-2.46 2.46 12.913 12.912-12.913 12.912 2.46 2.46 12.912-12.913 12.912 12.913z" fill="#021e24" fillRule="nonzero"></path></svg>
    );

    function skipRestricted(restricted_fragments, seconds, tcin_secs) {
        if (restricted_fragments === null || restricted_fragments.length === 0) {
            return true;
        }

        //console.log("[skip_restricted] ar_restricted_fragments:",restricted_fragments);
        //console.log("current time 2:", parseInt( seconds ) );

        // current_time_in_seconds. curretn partial time and tcin (the offset of the video fragment)
        const player_current_time = parseInt( seconds)
        const absolute_player_current_time = player_current_time + parseInt( tcin_secs )

        //console.log("absolute_player_current_time:",absolute_player_current_time, player_current_time);


        // iterate

        for (let i = 0; i < restricted_fragments.length; i++) {

            const item = restricted_fragments[i]
            const current_tcin_secs = item.tcin_secs
            const current_tcout_secs = item.tcout_secs

            if (absolute_player_current_time > current_tcin_secs && absolute_player_current_time < current_tcout_secs) {
                // Jump to end skipping this time rage
                const time_to_jump_secs = current_tcout_secs - tcin_secs
                reactPlayerRef.current.seekTo( time_to_jump_secs, 'seconds');

                //console.log("+++ Jumped to end time :",absolute_player_current_time, current_tcout_secs);
                //console.log("item:",item, "tcin_secs", tcin_secs, "player_current_time", player_current_time, "time_to_jump_secs",time_to_jump_secs );
            }
        }
        return true
    }

    return (
        <Modal
            open={open}
            onClose={toggleModal}
            closeIcon={closeIcon}
            >
            {props.video ?
                <ReactPlayer
                    url={ props.video}
                    ref={reactPlayerRef}
                    playing={true}
                    controls={true}

                    onProgress={(progress) => {
                        skipRestricted(props.exclude, progress.playedSeconds, 0)
                    }}
                    config={{
                        file: {
                            /*attributes: {
                                crossOrigin: "same-origin",
                                crossorigin="anonymous",
                            },*/
                            tracks: [{
                                kind: 'subtitles',
                                src: props.subtitle,
                                srcLang: getCurrentLanguage(),
                                default: true
                            }]
                        },
                    }}
                    width="100%"
                    height="100%"
                />
            : ''}
            {props.name && props.url ?
            <div className="columns is-multiline mb-l">
                <div className="column is-6-desktop is-12-touch">
                    <h1 className="is-size-4">{ props.name}</h1>
                </div>
                <div className="column is-6-desktop is-12-touch">
                {/* props.rel ?
                    <ul role="list" className="columns pl-s is-multiline is-flex tags">
                    {
                        props.rel.map(
                            (res, i) => {
                            return <>
                                <li key={res.term_id}><Link to={"/search/term/"+res.term_id}>{res.term}</Link></li>
                            </>
                            }
                        )
                    }
                    </ul>
                : ''*/}
                    <Link to={props.url} className="button has-background-primary has-text-white is-block has-text-centered is-medium is-fullwidth">
                        {translate('Veure tota l\'entrevista')}
                    </Link>
                </div>
            </div>
            : ''}
        </Modal>
    )
}
