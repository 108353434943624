import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { domain, getInterviews } from '../../assets/js/api';
import { translate, informantNameDisplay, getPosterframe } from '../../assets/js/functions';
import { Link } from 'react-router-dom'
import LayoutDeafult from "../../components/Layout/Default";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import { getAllByPlaceholderText } from '@testing-library/react';


export default function Testimonis(props){

    const [data, setData] = useState(null);
    const [dataComplete, setDataComplete] = useState(null);
    const title = translate("Testimonis")

    const [search, setSearch] = useState('');
    const [letter, setLetter] = useState('');

    const alpha = Array.from(Array(26)).map((e, i) => i + 65);
    const alphabet = alpha.map((x) => String.fromCharCode(x));


    function localeContains(base, sub) {
        if(sub === "") return true;
        if(!sub || !base.length) return false;
        sub = ""+sub;
        if(sub.length>base.length) return false;
        let ascii = s => s.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        return ascii(base).includes(ascii(sub));
    }

    function localeStart(base, letter) {
        if(letter === "") return true;
        letter = letter.toLowerCase();
        let ascii = s => s.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
        return ascii(base).startsWith(letter);
    }

    function filterSearch(data) {
        if (search === '') {
            return data;
        }
        return data.filter((value) => {
            if (localeContains(value.informant[0].surname, search)) {
                return true;
            }
            if (localeContains(value.informant[0].name, search)) {
                return true;
            }
            return false;
        });
    }

    function filterLetter(data, letter) {
        if (letter === '') {
            return data;
        }
        return data.filter((value) => {
            if (localeStart(value.informant[0].surname, letter)) {
                return true;
            }
            return false;
        });
    }

    function handleSubmit(event) {
        event.preventDefault();
        setLetter('');
        setData(filterSearch(dataComplete));
    }

    function handleInputChange(event) {
        const target = event.target;
        setSearch(target.value);
    }

    function handleLetterChange(newLetter, event) {
        event.preventDefault();
        setSearch('');
        if (newLetter != letter) {
            setLetter(newLetter);
        } else {
            setLetter('');
        }
        setData(filterLetter(dataComplete, newLetter));
    }

    useEffect(() => {
        getInterviews(search).then(res => {
            let data = res.data.result ? res.data.result : null;
            if (data) {
                data = data.filter((value) => {
                    if (!value.informant || value.informant.length === 0) {
                        return false;
                    }
                    return true;
                });

                data.sort((a, b) => {
                    const comp = a.informant[0].surname.localeCompare(b.informant[0].surname);
                    if (comp === 0) {
                        return a.informant[0].name.localeCompare(b.informant[0].name);
                    }
                    return comp;
                });
            }
            setData(data);
            setDataComplete(data);
        });
    }, []);

    return (
        <LayoutDeafult pageMeta={{
            title: title
        }}>
            <Breadcrumbs currentSite={title}/>
            { data ?
            <>
                <div className="wrapper">
                    <div className="columns  is-centered">
                        <div className="column pb-0  is-4-desktop is-10-tablet mt-xl has-text-centered">
                            <h1 className="mb-s">{title}</h1>
                            <p>
                                {parse(translate('Gravacions realitzades dins el projecte <span class="has-text-weight-semibold">Preservem la memòria </span>i ordenades alfabèticament pel primer cognom'))}
                            </p>
                        </div>
                    </div>
                </div>

                <div className="wrapper">
                    <div className="columns is-centered">
                        <div className="column pb-0 is-4-desktop is-10-tablet mt-s has-text-centered">
                            <form className="search-form" id="search-form" onSubmit={handleSubmit}>
                                <fieldset className="flow">
                                    <div className="field">
                                        <label className="label is-hidden" for="cercador">{translate('Cercador')}</label>
                                        <div className="control">
                                            <input onChange={handleInputChange} value={search} type="search" id="cercador" className="input" placeholder={translate('Cerca per nom o cognom de la infermera')+'…'} />
                                        </div>
                                    </div>
                                    <div className="has-text-centered">
                                        <button type="submit" name="enviar" className="button" aria-label="Cercar">
                                            <svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <g transform="translate(-758.000000, -529.000000)" stroke="#fff" strokeWidth="3">
                                                        <g transform="translate(760.000000, 531.000000)">
                                                            <ellipse cx="7.77029255" cy="7.34075652" rx="7.77029255" ry="7.34075652"></ellipse>
                                                            <line x1="13.5598328" y1="12.805282" x2="18" y2="17" strokeLinecap="round" strokeLinejoin="round"></line>
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg>
                                        </button>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="wrapper">
                    <div className="columns is-centered">
                        <div className="column is-6-desktop is-12-tablet mt-s has-text-centered">
                            <ol role="list" className="columns pt-s px-s is-flex is-justify-content-center is-multiline abc">
                                {alphabet.map((current) => {
                                return <li className="pl-xs">
                                    <a
                                        className={(letter === current)?'is-size-7 has-text-weight-bold is-underlined':'is-size-7 has-text-primary-light'}
                                        href={'#'+current}
                                        onClick={(event) => handleLetterChange(current, event)}>
                                            {current}
                                    </a>
                                </li>
                                })}
                            </ol>
                            <div className="mt-l">
                                <p className="is-size-5">{data.length} {translate('resultats')}</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="wrapper">
                    <div className="columns is-multiline">
                        {
                            data.map(
                                (res, i) => {

                                return <>
                                    <div className="column is-4">
                                        <div className="box-testimonis is-flex is-flex-direction-column h-100 bbso-1-primary">
                                            <Link className="h-100" to={"/testimonis/"+res.section_id}>
                                                { res.audiovisual && res.audiovisual.length > 0 ?
                                                <div className="media">
                                                    <img src={getPosterframe(domain+(res.audiovisual[0].video))} alt={res.title} />
                                                    <div className="play">
                                                        <svg width="20px" height="24px" viewBox="0 0 20 24" version="1.1">
                                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                                <g transform="translate(-309.000000, -881.000000)" fill="#FFFFFF">
                                                                    <polygon transform="translate(319.270943, 892.898230) rotate(90.000000) translate(-319.270943, -892.898230) " points="319.270943 883.39823 330.452359 902.39823 308.089527 902.39823"></polygon>
                                                                </g>
                                                            </g>
                                                        </svg>
                                                        <span>{translate('Reproduir')}</span>
                                                    </div>
                                                </div>
                                                : '' }
                                                <div className="txt is-flex-grow-1 flow ">
                                                    <h2>{informantNameDisplay(res.informant[0])}</h2>
                                                    {/*<p>{parse(res.abstract)}</p>*/}
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </>
                                }
                            )
                        }
                    </div>
                </div>
            </>
                :
            <div className="wrapper">
                <div className="columns mt-xl is-centered loading">
                    <svg width="50" height="50" viewBox="0 0 24 24"><path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/><path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"><animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite"/></path></svg>
                </div>
            </div>
            }
        </LayoutDeafult>
    )
}
