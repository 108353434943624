import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getParents } from '../../assets/js/api';
import { translate } from '../../assets/js/functions';
//import './assets/scss/styles.scss';

export default function Breadcrumbs (props){

    const [data, setData] = useState(null);

    useEffect(() => {
        if(props.currentId) getParents(props.currentId).then(res => setData(res.data.result.reverse()));
    }, [props.currentId]);

    return (
        <div className="wrapper">
            <div className={"breadcrumbs pt-l "+(props.customCssClass ? props.customCssClass : '')} aria-label={translate('Menú principal')}>
                <ul className="pl-0" role="list">
                    <li className="pr-2">
                        <Link to={"/"}>{translate("Inici")}</Link>
                    </li>
                    {
                        props.beforeData && props.beforeData.map(res => <li className="pr-2"><Link key={res.name} to={res.url}>{res.name}</Link></li>)
                    }
                    {
                        props.staticData ? props.staticData.map(res => <li className="pr-2"><Link key={res.name} to={res.url}>{res.name}</Link></li>) :
                        (data && data.map(res => <li className="pr-2"><Link key={res.term_id} to={res.term_id}>{res.term}</Link></li>))
                    }
                    {
                        props.currentSite && <li className="pr-2"><a href="" aria-current="page">{props.currentSite}</a></li>
                    }
                </ul>
            </div>
        </div>
    )
}
