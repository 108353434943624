import React, { useState } from 'react'
import { domain } from '../assets/js/api';
import { translate, getPosterframe, informantNameDisplay } from '../assets/js/functions';
import VideoModal from "./Modals/VideoModal";

export default function Fragment(props){

    const res = props.data;
    const fragment = props.fragment;
    const [open, setOpen] = useState(false);
    return <>
    <div className="column is-half-tablet is-one-third-desktop">
        <div className="box-fragments is-flex is-flex-direction-column h-100">
            <a href={domain+fragment.video_url} className="h-100 video" onClick={(event) => {event.preventDefault();setOpen(true)}} >
                <div className="media">
                    <img src={getPosterframe(domain+(fragment.posterframe_url))} alt={res.title} />
                    <div className="play">
                        <svg width="20px" height="24px" viewBox="0 0 20 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g transform="translate(-309.000000, -881.000000)" fill="#FFFFFF">
                                    <polygon transform="translate(319.270943, 892.898230) rotate(90.000000) translate(-319.270943, -892.898230) " points="319.270943 883.39823 330.452359 902.39823 308.089527 902.39823"></polygon>
                                </g>
                            </g>
                        </svg>
                        <span className="is-size-9">{translate('Reproduir')}</span>
                    </div>
                </div>
                <div className="txt is-flex-grow-1 flow">
                    <h2>{informantNameDisplay(res.informant[0])}</h2>
                </div>
            </a>
        </div>
    </div>
    <VideoModal
        open={open}
        video={domain+fragment.video_url}
        subtitle={fragment.subtitles_url}
        name={informantNameDisplay(res.informant[0])}
        url={"/testimonis/"+JSON.parse(res.audiovisual)[0]}
        toggleModal={() => {setOpen(!open)}} />
    </>;
}
