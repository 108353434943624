import React, { useEffect, useState } from 'react';
import { translate, termsSort, fragmentsSort } from '../../assets/js/functions';
import { getExploreChronologic, getFragmentfromIndexLocator } from '../../assets/js/api';
import LayoutDefaultDark from "../../components/Layout/DefaultDark";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import TermTree from '../../components/TimeLine/TermTree';
import Lateral from '../../components/Terms/Lateral';

export default function TimeLine(props){

    const title = translate("Línia de temps")
    const [data, setData] = useState(null);
    const [selectedTerm, setSelectedTerm] = useState(null);

    function updateSelectedTerm(term) {
        setSelectedTerm(null);
        if (term === null) {
            return;
        }

        const promise = term.indexation.map(
            i => {
                return getFragmentfromIndexLocator(JSON.stringify(i)).then(
                    interview => {
                        return interview.data.result;
                    }
                )
            }
        )
        Promise.all(promise).then(
            response => {
                let fragments = []
                let terms = []
                let have = [term.term_id];
                response.map(value => {
                    if (value[0].result && value[1].result && value[1].result.length > 0) {
                        fragments.push([value[0].result, value[1].result[0]]);
                        if (value[0].result.terms.length > 0) {
                            value[0].result.terms.map(term => {
                                if (!have.includes(term.term_id)) {
                                    terms.push(term);
                                    have.push(term.term_id);
                                }
                            })

                        }
                    }
                });
                term.combina = terms.length > 0?termsSort(terms):null;
                term.fragments = fragments.length > 0?fragmentsSort(fragments):null;
                if (terms.length > 0 || fragments.length > 0) {
                    setSelectedTerm(term);
                }
            }
        )
    }

/*
La informació de la línia de temps procedeix dels termes dependents dels
descriptors cronològics [hierachy1_251].
El rang de temps està introduït en cada terme dins del “marc temporal”, de tal manera que aquesta informació ja hauria de sortir estructurada.

funció records ts_chronological
filtrar per parent hierarchy1_251, a partir

*/
    function resetChilds()
    {
        const newChilds = data.map(value => {
            value.show = false;
            return value;
        })
        setData(newChilds);
    }

    function showChildTerm(term, show)
    {
        const newChilds = data.map(value => {
            if (value.term_id === term.term_id) {
                value.show = show;
            }
            return value;
        })
        setData(newChilds);
    }

    useEffect(() => {
        getExploreChronologic('hierarchy1_251').then(res => {
            setData(res);
        });
    }, []);

    return (
        <LayoutDefaultDark pageMeta={{
            title: title,
            customCssClass: 'linia'
        }}>
            <Breadcrumbs currentSite={title} customCssClass="blanc"/>

            <div className="wrapper">
            <div className="columns is-multiline">
                <div className="column is-12">
                    <h1 className="mb-s has-text-white">{ translate('Línia de temps') }</h1>
                </div>
            </div>
            <div className="columns is-multiline is-flex is-flex-direction-row-reverse">
                { selectedTerm !== null ?
                <Lateral term={selectedTerm} closeCallback={setSelectedTerm}></Lateral>
                : '' }
                <div className="column">
                    { data ?
                    <ul className="temps">
                        { data.map(res => {
                            return <TermTree key={res.term_id} term={res} level={0} updateTerm={updateSelectedTerm} resetChilds={resetChilds} showChildTerm={showChildTerm}></TermTree>
                        })}
                    </ul>
                    : '' }
                </div>

            </div>
        </div>
        </LayoutDefaultDark>
    )
}
