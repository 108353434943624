import React, { useState } from 'react';
import { Link } from 'react-router-dom'

export default function LateralTerm({term, addFilter, removeFilter, hidden = false, active = false}){

    function updateActive()
    {
        //const newActive = filter.find(item => item.term_id === term.term_id);
        const newActive = !active;
        if (newActive) {
            addFilter(term);
        } else {
            removeFilter(term);
        }
    }

    return (
        <>
        <li className={(hidden?'is-hidden':'')} key={'lateral_'+term.term_id}>
            <Link to={"/search/term/"+term.term_id} className={active?'current':''} onClick={(event) => {event.preventDefault();updateActive()}}>
                {term.term}
            </Link>
        </li>
        </>
    )
}
