import React from 'react';
import Helmet from 'react-helmet';
import Logo_ca from '../../assets/img/logo-ca-portada-blanc.svg'
import Logo_es from '../../assets/img/logo-es-portada-blanc.svg'
import Logo_en from '../../assets/img/logo-en-portada-blanc.svg'
import Slider1 from '../../assets/img/imatge-slider-portada-1.jpg'
import Slider2 from '../../assets/img/imatge-slider-portada-2.jpg'
import Slider3 from '../../assets/img/imatge-slider-portada-3.jpg'
import Slider4 from '../../assets/img/imatge-slider-portada-4.jpg'
import Slider5 from '../../assets/img/imatge-slider-portada-5.jpg'
import Slider6 from '../../assets/img/imatge-slider-portada-6.jpg'
import Slider from "react-slick";

import { translate, getCurrentLanguage, getLanguages, getLanguageName, getLanguageShortName } from '../../assets/js/functions';

const LayoutHome =({pageMeta, children}) =>{

    const Logo = {
        ca: Logo_ca,
        es: Logo_ca,//Logo_es,
        en: Logo_ca//Logo_en
    }

    const settings = {
        autoplay: true,
        infinite: true,
        slidesToShow: 1,
        fade: true,
        cssEase: 'linear',
        speed: 2000,
        autoplaySpeed: 5000,
        dots: false,
        arrows:false
    };

    return(
        <>
        <Helmet>
            <html className="no-js" lang="ca" />
            <meta charset="UTF-8" />
            <meta name="viewport" content="width=device-width, initial-scale=1" />
            {(pageMeta.desc)?<meta name="description" content={pageMeta.desc} />:''}
            <meta http-equiv="X-UA-Compatible" content="IE=edge" />
            <title>{(pageMeta.title)?' | '+pageMeta.title:''}{translate('Preservem la memòria')} | {translate('Col·legi Oficial Infermeres i Infermers Barcelona')}</title>

            <script>
                document.documentElement.className = document.documentElement.className.replace(
                    /(?:^|\s)no-js(?!\S)/g,
                    ""
                );
                document.documentElement.className += " js";
            </script>

            {/* favicons */}
            <link rel="apple-touch-icon" sizes="57x57" href="/favicons/apple-touch-icon-57x57.png" />
            <link rel="apple-touch-icon" sizes="60x60" href="/favicons/apple-touch-icon-60x60.png" />
            <link rel="apple-touch-icon" sizes="72x72" href="/favicons/apple-touch-icon-72x72.png" />
            <link rel="apple-touch-icon" sizes="76x76" href="/favicons/apple-touch-icon-76x76.png" />
            <link rel="apple-touch-icon" sizes="114x114" href="/favicons/apple-touch-icon-114x114.png" />
            <link rel="apple-touch-icon" sizes="120x120" href="/favicons/apple-touch-icon-120x120.png" />
            <link rel="apple-touch-icon" sizes="144x144" href="/favicons/apple-touch-icon-144x144.png" />
            <link rel="apple-touch-icon" sizes="152x152" href="/favicons/apple-touch-icon-152x152.png" />
            <link rel="apple-touch-icon" sizes="180x180" href="/favicons/apple-touch-icon-180x180.png" />
            <link rel="icon" type="image/png" href="/favicons/favicon-32x32.png" sizes="32x32" />
            <link rel="icon" type="image/png" href="/favicons/favicon-194x194.png" sizes="194x194" />
            <link rel="icon" type="image/png" href="/favicons/favicon-96x96.png" sizes="96x96" />
            <link rel="icon" type="image/png" href="/favicons/android-chrome-192x192.png" sizes="192x192" />
            <link rel="icon" type="image/png" href="/favicons/favicon-16x16.png" sizes="16x16" />
            <link rel="manifest" href="/manifest.json" />
            <link rel="mask-icon" href="/favicons/safari-pinned-tab.svg" color="#fff" />
            <meta name="msapplication-TileColor" content="#013f4c" />
            <meta name="msapplication-TileImage" content="favicon/mstile-144x144.png" />
            <meta name="theme-color" content="#013f4c" />
            {/* /favicons */}
            <body id="top" className={pageMeta.customCssClass ? 'home '+pageMeta.customCssClass : 'home'} />
        </Helmet>

        {/* main-header */}
        <header className="main-header pb-x is-relative">
            <div className="wrapper">
                <div className="columns">
                    <div className="column">
                        <h1 className="site-logo mt-m pb-xs"><img src={Logo[getCurrentLanguage()]} alt={translate('Preservem la memòria. Col·legi Oficial Infermers i Infermeres Barcelona')} width="360" height="70"/></h1>
                    </div>
                    <div className="column">
                        <ul role="list" className="lang-menu   lang-menu--home mt-xl is-flex is-justify-content-flex-end upp link-dn has-text-weight-bold">
                        { getLanguages().map(
                            lang =>
                            <li key={'lang_'+lang} className={lang === getCurrentLanguage() ? "btso-1-white" : "btso-1-transparent"}>
                                <a hrefLang={lang} href={'/'+lang} lang={lang} className={lang === getCurrentLanguage() ? "pt-2xs px-xs is-block selected" : "pt-2xs px-xs is-block"}>
                                    <abbr title={getLanguageName(lang)}>{getLanguageShortName(lang)}</abbr>
                                </a>
                            </li>
                        ) }
                        </ul>
                    </div>
                </div>



            </div>
        </header>
        {/* /main-header */}

        {/* main-content */}
        <main className="main-content flow mb-xl" id="main">
            {/* Slider */}
            <Slider className='slider slider--home m-0' {...settings}>
                <div className="h-100">
                    <img src={Slider1} alt="" className="w-100 h-100" loading="lazy" />
                </div>
                <div className="h-100">
                    <img src={Slider2} alt="" className="w-100" loading="lazy" />
                </div>
                <div className="h-100">
                    <img src={Slider3} alt="" className="w-100" loading="lazy" />
                </div>
                <div className="h-100">
                    <img src={Slider4} alt="" className="w-100" loading="lazy" />
                </div>
                <div className="h-100">
                    <img src={Slider5} alt="" className="w-100" loading="lazy" />
                </div>
                <div className="h-100">
                    <img src={Slider6} alt="" className="w-100" loading="lazy" />
                </div>
            </Slider>
            <div className="slider slider--home m-0">
            </div>
            {/* /Slider */}
            {/* Títol */}
            <div className="wrapper">
                <div className="columns">
                    <div className="column is-6-fullhd is-7-widescreen is-8-desktop is-8-tablet mt-l">
                        <h2 className="has-text-white is-size-4 mt-2xl mb-s">{translate('Preservem la memòria') }</h2>
                        <h3 className="has-text-white is-size-7 has-text-weight-normal">{translate('Repositori virtual d\'Infermeria de Barcelona') }</h3>
                    </div>
                </div>
            </div>
            {/* /Títol */}
            {children}
        </main>
        {/* /main-content */}
        </>
    )
}

export default LayoutHome;