import React, { useEffect } from 'react';
import { leafletIcon } from '../../assets/js/functions';

export default function LateralMap(props){

    useEffect(() => {
        if (props.point) {
            const icon = leafletIcon();
            let L = window.L;
            let map = new L.map(document.getElementById('map'));
            L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
                attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
            }).addTo(map);

            const target = L.latLng(props.point.lat, props.point.lon);
            L.marker(target, {icon: icon}).addTo(map)
            map.setView(target, 12);
        }
    }, [props.point]);

    return (
        <>
        <div className="is-flex is-align-items-center mb-xs">
            <svg className="mr-xs" width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
                <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <g id="11---TEMES---Facultat-de-Medicina-UB" transform="translate(-898, -540)">
                        <g id="ico-ubicacio" transform="translate(898, 540)">
                            <polygon id="Path" points="0 0 30 0 30 30 0 30"></polygon>
                            <polyline id="Path" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" points="15 23.125 11.25 21.25 3.75 25 3.75 8.75 11.25 5 18.75 8.75 26.25 5 26.25 14.375"></polyline>
                            <line x1="11.25" y1="5" x2="11.25" y2="21.25" id="Path" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                            <line x1="18.75" y1="8.75" x2="18.75" y2="15.625" id="Path" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                            <path d="M26.40125,25.15125 C27.4739773,24.0788466 27.795019,22.4658159 27.2146566,21.0643979 C26.6342942,19.6629799 25.2668365,18.7491992 23.75,18.7491992 C22.2331635,18.7491992 20.8657058,19.6629799 20.2853434,21.0643979 C19.704981,22.4658159 20.0260227,24.0788466 21.09875,25.15125 C21.62125,25.675 22.505,26.4575 23.75,27.5 C25.06375,26.3875 25.94875,25.605 26.40125,25.15125 L26.40125,25.15125 Z" id="Path" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            <line x1="23.75" y1="22.5" x2="23.75" y2="22.5125" id="Path" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                        </g>
                    </g>
                </g>
            </svg>
            <span className="is-uppercase has-text-weight-semibold">Ubicació</span>
        </div>
        <div className="mapa-view ml-0 pl-0 mb-l pb-0">
            <div id="map" className="w-100"></div>
        </div>
        </>
    )
}
