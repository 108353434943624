import React, { useState, useEffect } from 'react'
import { getThematicIndexations, getFragmentfromIndexLocator } from '../../assets/js/api';
import { Link } from 'react-router-dom'
import { translate, termsSort, fragmentsSort } from '../../assets/js/functions';
import LayoutDeafult from "../../components/Layout/Default";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Fragment from '../../components/Fragment';

export default function TGerm(props){

    const title = translate('Buscador');
    const [term, setTerm] = useState(null);
    const [terms, setTerms] = useState(null);
    const [fragments, setFragments] = useState(null);
    const breadcrumbsStatic = [
        {name: translate("Temes"), url: "/terms"}
    ];


//getThematicIndexations -> a partir d'un terme torna indexacions
//getTermsCombined -> torna termes combinats????? o indexacions




    useEffect(() =>{
        if (props.match){
            const dataId = props.match.params.id;
            if (dataId) {
                getThematicIndexations(dataId).then(res => {
                    const result = res.data.result && res.data.result.length > 0 ? res.data.result[0] : null;
                    if (result) {
                        setTerm(result);
                        const promise = JSON.parse(result.indexation).map(
                            i => {
                                return getFragmentfromIndexLocator(JSON.stringify(i)).then(
                                    interview => {
                                        return interview.data.result;
                                    }
                                )
                            }
                        )
                        Promise.all(promise).then(
                            response => {
                                let fragments = []
                                let terms = []
                                let have = [dataId];
                                response.map(value => {
                                    if (value[0].result && value[1].result && value[1].result.length > 0) {
                                        fragments.push([value[0].result, value[1].result[0]]);
                                        if (value[0].result.terms.length > 0) {
                                            value[0].result.terms.map(term => {
                                                if (!have.includes(term.term_id)) {
                                                    terms.push(term);
                                                    have.push(term.term_id);
                                                }
                                            })

                                        }
                                    }
                                });
                                setTerms(terms.length > 0?termsSort(terms):null);
                                setFragments(fragments.length > 0?fragmentsSort(fragments):null);
                            }
                        )
                    }
                });
            }
        }
    }, [props.match]);

    return (
        <LayoutDeafult pageMeta={{
            title: title
        }}>
            { term ?
            <>
            <Breadcrumbs staticData={breadcrumbsStatic} currentSite={term.term}/>
            <div className="wrapper">
                <div className="columns  is-centered">
                    <div className="column pb-l  is-10-desktop is-10-tablet mt-xl has-text-centered">
                        <h1 className="mb-s">{term.term}</h1>
                    </div>
                </div>
            </div>


            <div className="wrapper">
                <div className="columns is-multiline">
                    <div className="column is-5-desktop is-5-tablet is-12-mobile flow">
                        <Link className="button has-background-primary has-text-white is-block has-text-centered is-medium is-fullwidth wrap-button-text" to={'/terms/term/'+term.term_id}>{ translate('Veure \`?\` al tesaure').replace('?', term.term) }</Link>
                        { terms ?
                        <>
                        <h2 className="is-flex is-align-items-center is-size-7">
                            <svg className="mr-xs" width="35px" height="35px" viewBox="0 0 35 35" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-120, -372)">
                                        <g transform="translate(120, 372)">
                                            <polygon points="0 0 35 0 35 35 0 35"></polygon>
                                            <path d="M8.649375,10.115 L10.50875,16.3114583 M18.685625,24.49125 L24.885,26.350625" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <line x1="17.0377083" y1="17.9622917" x2="25.43625" y2="9.56375" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                            <path d="M5.83333333,8.02083333 C5.83333333,8.80235182 6.25026846,9.52450469 6.92708332,9.91526394 C7.60389818,10.3060232 8.43776848,10.3060232 9.11458335,9.91526394 C9.79139821,9.52450469 10.2083333,8.80235182 10.2083333,8.02083333 C10.2083333,6.81271044 9.22895622,5.83333333 8.02083333,5.83333333 C6.81271044,5.83333333 5.83333333,6.81271044 5.83333333,8.02083333" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M24.7916667,8.02083333 C24.7916667,9.22895622 25.7710438,10.2083333 26.9791667,10.2083333 C28.1872896,10.2083333 29.1666667,9.22895622 29.1666667,8.02083333 C29.1666667,6.81271044 28.1872896,5.83333333 26.9791667,5.83333333 C25.7710438,5.83333333 24.7916667,6.81271044 24.7916667,8.02083333" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M24.7916667,26.9791667 C24.7916667,28.1872896 25.7710438,29.1666667 26.9791667,29.1666667 C28.1872896,29.1666667 29.1666667,28.1872896 29.1666667,26.9791667 C29.1666667,25.7710438 28.1872896,24.7916667 26.9791667,24.7916667 C25.7710438,24.7916667 24.7916667,25.7710438 24.7916667,26.9791667" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <path d="M12.3958333,29.1666667 C16.020202,29.1666667 18.9583333,26.2285353 18.9583333,22.6041667 C18.9583333,18.979798 16.020202,16.0416667 12.3958333,16.0416667 C8.77146466,16.0416667 5.83333333,18.979798 5.83333333,22.6041667 C5.83333333,26.2285353 8.77146466,29.1666667 12.3958333,29.1666667 Z" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span className="is-uppercase mr-xs">{translate('Combina amb')}</span>
                            <span className="has-text-weight-normal">{ terms.length } {translate('resultats')}</span>
                        </h2>
                        <ul role="list" className="columns mt-xl mx-0 is-multiline is-flex  tags">
                        {
                            terms.map(
                                (res, i) => {
                                return <>
                                    <li key={'term_'+res.term_id}><Link to={"/search/term/"+res.term_id}>{res.term}</Link></li>
                                    </>
                                }
                            )
                        }
                        </ul>
                        </>
                        : '' }
                    </div>
                    <div className="column is-offset-1 is-6-desktop is-6-tablet is-12-mobile flow">
                    { fragments ?
                        <>
                        <h2 className="is-flex is-align-items-center is-size-7">
                            <svg className="mr-xs" width="35px" height="35px" viewBox="0 0 35 35" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-979, -372)">
                                        <g transform="translate(979, 372)">
                                            <polygon points="0 0 35 0 35 35 0 35"></polygon>
                                            <path d="M5.83333333,8.75 C5.83333333,7.13916948 7.13916948,5.83333333 8.75,5.83333333 L26.25,5.83333333 C27.8608305,5.83333333 29.1666667,7.13916948 29.1666667,8.75 L29.1666667,26.25 C29.1666667,27.8608305 27.8608305,29.1666667 26.25,29.1666667 L8.75,29.1666667 C7.13916948,29.1666667 5.83333333,27.8608305 5.83333333,26.25 L5.83333333,8.75 Z" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                            <line x1="11.6666667" y1="5.83333333" x2="11.6666667" y2="29.1666667" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                            <line x1="23.3333333" y1="5.83333333" x2="23.3333333" y2="29.1666667" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                            <line x1="5.83333333" y1="11.6666667" x2="11.6666667" y2="11.6666667" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                            <line x1="5.83333333" y1="23.3333333" x2="11.6666667" y2="23.3333333" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                            <line x1="5.83333333" y1="17.5" x2="29.1666667" y2="17.5" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                            <line x1="23.3333333" y1="11.6666667" x2="29.1666667" y2="11.6666667" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                            <line x1="23.3333333" y1="23.3333333" x2="29.1666667" y2="23.3333333" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            <span className="is-uppercase mr-xs">{translate('Fragments d\'entrevista')}</span>
                            <span className="has-text-weight-normal">{ fragments.length } {translate('resultats')}</span>
                        </h2>

                        <div className="columns is-multiline">
                        {
                            fragments.map(
                                (res, i) => {
                                return <Fragment data={res[1]} fragment={res[0]}></Fragment>
                                }
                            )
                        }
                        </div>
                        </>
                    : '' }
                    </div>
                </div>
            </div>
            </>
            :
            <div className="wrapper">
                <div className="columns mt-xl is-centered loading">
                    <svg width="50" height="50" viewBox="0 0 24 24"><path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/><path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"><animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite"/></path></svg>
                </div>
            </div>
            }
        </LayoutDeafult>
    )
}
