import React from 'react'
import SearchResultFragment from "../Search/SearchResultFragment";

export default function SearchResult(props){

    const res = props.data;

    return res.fragments.map((fragment) => {
        if (fragment.video_url) {
            return <SearchResultFragment data={res} fragment={fragment}></SearchResultFragment>
        }
        return '';
    });
}
