import React from 'react';
import { translate } from '../../assets/js/functions';
import Fragment from '../../components/Fragment';

export default function LateralFragments({fragments}){

    return (
        <>
{ fragments && fragments.length > 0 ?
<>
<div className="is-flex is-align-items-center mt-l mb-xs">
    <svg className="mr-xs" width="35px" height="35px" viewBox="0 0 35 35" version="1.1">
        <title>ico-fragments</title>
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-979, -372)">
                <g transform="translate(979, 372)">
                    <polygon points="0 0 35 0 35 35 0 35"></polygon>
                    <path d="M5.83333333,8.75 C5.83333333,7.13916948 7.13916948,5.83333333 8.75,5.83333333 L26.25,5.83333333 C27.8608305,5.83333333 29.1666667,7.13916948 29.1666667,8.75 L29.1666667,26.25 C29.1666667,27.8608305 27.8608305,29.1666667 26.25,29.1666667 L8.75,29.1666667 C7.13916948,29.1666667 5.83333333,27.8608305 5.83333333,26.25 L5.83333333,8.75 Z" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <line x1="11.6666667" y1="5.83333333" x2="11.6666667" y2="29.1666667" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="23.3333333" y1="5.83333333" x2="23.3333333" y2="29.1666667" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="5.83333333" y1="11.6666667" x2="11.6666667" y2="11.6666667" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="5.83333333" y1="23.3333333" x2="11.6666667" y2="23.3333333" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="5.83333333" y1="17.5" x2="29.1666667" y2="17.5" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="23.3333333" y1="11.6666667" x2="29.1666667" y2="11.6666667" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                    <line x1="23.3333333" y1="23.3333333" x2="29.1666667" y2="23.3333333" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                </g>
            </g>
        </g>
    </svg>
    <span className="is-uppercase has-text-weight-semibold mr-xs">{translate('Fragments d\'entrevista')}</span>
    <span className="">{ fragments.length } {translate('resultats')}</span>
</div>

<div className="columns is-multiline">
    {
        fragments.map(
            (res, i) => {
            return <Fragment data={res[1]} fragment={res[0]}></Fragment>
            }
        )
    }
</div>
</>
:''}
        </>
    )
}
