import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { domain } from '../../assets/js/api';
import { translate, getPosterframe, informantNameDisplay } from '../../assets/js/functions';
import VideoModal from "../Modals/VideoModal";

export default function Fragment(props){

    const res = props.testimoni;
    const fragment = props.fragment;
    const [open, setOpen] = useState(false);

    return <>
    <div className="column is-4 mb-l">
        <div className="box-fragments is-flex is-flex-direction-column h-100">
            <a href={domain+fragment.video_url} className="video" onClick={(event) => {event.preventDefault();setOpen(true)}} >
                <div className="media">
                    <img src={getPosterframe(domain+(fragment.posterframe_url))} alt="" />
                    <div className="play">
                        <svg width="20px" height="24px" viewBox="0 0 20 24" version="1.1">
                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                <g transform="translate(-309.000000, -881.000000)" fill="#FFFFFF">
                                    <polygon transform="translate(319.270943, 892.898230) rotate(90.000000) translate(-319.270943, -892.898230) " points="319.270943 883.39823 330.452359 902.39823 308.089527 902.39823"></polygon>
                                </g>
                            </g>
                        </svg>
                        <span>{translate('Reproduir')}</span>
                    </div>
                </div>
            </a>
            <div className="txt is-flex-grow-1 flow ">
            { fragment.terms ?
                <ul role="list" className="columns my-s pl-s is-multiline is-flex tags">
                {
                    fragment.terms.map(
                        (res, i) => {
                        return <>
                            <li key={'testimoni_fragment_'+res.term_id}><Link to={"/search/term/"+res.term_id}>{res.term}</Link></li>
                        </>
                        }
                    )
                }
                </ul>
            : ''}
            </div>

        </div>
    </div>
    <VideoModal
        open={open}
        video={domain+fragment.video_url}
        subtitle={fragment.subtitles_url}
        name={informantNameDisplay(res.informant[0])}
        url={"/testimonis/"+res.audiovisual[0].section_id}
        rel={fragment.terms}
        toggleModal={() => {setOpen(!open)}} />
    </>;
}
