import React, { useEffect, useState } from 'react';
import { termsSort, fragmentsSort } from '../../assets/js/functions';
import { Link } from 'react-router-dom'
import { getExploreTermChilds } from '../../assets/js/api';
import { getFragmentfromIndexLocator } from '../../assets/js/api';


export default function TermTree(props){

    //const [show, setShow] = useState(false);
    const [childs, setChilds] = useState(null);
    const [complete, setComplete] = useState(false);
    //const term = props.term;
    const level = props.level;
    const updateSelectedTerm = props.updateTerm;

    function resetChilds()
    {
        const newChilds = childs.map(value => {
            value.show = false;
            return value;
        })
        setChilds(newChilds);
    }

    function showChildTerm(term, show)
    {
        const newChilds = childs.map(value => {
            if (value.term_id === term.term_id) {
                value.show = show;
            }
            return value;
        })
        setChilds(newChilds);
    }

    useEffect(() => {
        /*const promise = props.term.indexation.map(
            i => {
                return getFragmentfromIndexLocator(JSON.stringify(i)).then(
                    interview => {
                        return interview.data.result;
                    }
                )
            }
        )
        Promise.all(promise).then(
            response => {
                let fragments = []
                let terms = []
                let have = [props.term.term_id];
                response.map(value => {
                    if (value[0].result && value[1].result && value[1].result.length > 0) {
                        fragments.push([value[0].result, value[1].result[0]]);
                        if (value[0].result.terms.length > 0) {
                            value[0].result.terms.map(term => {
                                if (!have.includes(term.term_id)) {
                                    terms.push(term);
                                    have.push(term.term_id);
                                }
                            })

                        }
                    }
                });
                props.term.combina = terms.length > 0?termsSort(terms):null;
                props.term.fragments = fragments.length > 0?fragmentsSort(fragments):null;
                if (terms.length > 0 || fragments.length > 0 || props.term.children.length > 0) {
                    //setComplete(true);
                }
            }
        )*/
        setComplete(true);
    }, [props.term]);

    function changeLevel() {
        if (childs === null &&
            !props.term.term_id.startsWith('inf_') &&
            !props.term.term_id.startsWith('mtg_') &&
            !props.term.term_id.startsWith('adm_') &&
            !props.term.term_id.startsWith('pers_')
        ) {
            let order = 'term';
            if (props.option === 'quan') {
                order = 'time';
            }
            getExploreTermChilds(props.term, order).then(res => {
                const newShow = !props.term.show;
                props.resetChilds();
                setChilds(res);
                props.showChildTerm(props.term, newShow);
                //setShow(newShow);
                updateSelectedTerm((newShow)?props.term:null);
            });
        } else {
            const newShow = !props.term.show;
            props.resetChilds();
            //setShow(newShow);
            props.showChildTerm(props.term, newShow);
            updateSelectedTerm((newShow)?props.term:null);
        }
    }

    return (
        <>
        <li key={'terms_tree_'+props.term.term_id} className={ props.term.show ? 'has-submenu':''}>
            <Link to={"/search/term/"+props.term.term_id} className="is-flex is-align-items-center" onClick={(event) => {event.preventDefault();changeLevel()}}>
                <span className="is-flex-grow-1">{props.term.term}</span>
                {props.term.location_map ?
                <svg className="mr-2xs" width="30px" height="30px" viewBox="0 0 30 30" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-898, -540)">
                            <g transform="translate(898, 540)">
                                <polygon points="0 0 30 0 30 30 0 30"></polygon>
                                <polyline stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" points="15 23.125 11.25 21.25 3.75 25 3.75 8.75 11.25 5 18.75 8.75 26.25 5 26.25 14.375"></polyline>
                                <line x1="11.25" y1="5" x2="11.25" y2="21.25" id="Path" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                <line x1="18.75" y1="8.75" x2="18.75" y2="15.625" id="Path" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                <path d="M26.40125,25.15125 C27.4739773,24.0788466 27.795019,22.4658159 27.2146566,21.0643979 C26.6342942,19.6629799 25.2668365,18.7491992 23.75,18.7491992 C22.2331635,18.7491992 20.8657058,19.6629799 20.2853434,21.0643979 C19.704981,22.4658159 20.0260227,24.0788466 21.09875,25.15125 C21.62125,25.675 22.505,26.4575 23.75,27.5 C25.06375,26.3875 25.94875,25.605 26.40125,25.15125 L26.40125,25.15125 Z" id="Path" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <line x1="23.75" y1="22.5" x2="23.75" y2="22.5125" id="Path" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                            </g>
                        </g>
                    </g>
                </svg>
                : ''}

                { complete ?
                <>
                {props.term.indexation && props.term.indexation.length > 0 ?
                <svg className="mr-2xs" width="35px" height="35px" viewBox="0 0 35 35" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-120, -372)">
                            <g transform="translate(120, 372)">
                                <polygon points="0 0 35 0 35 35 0 35"></polygon>
                                <path d="M8.649375,10.115 L10.50875,16.3114583 M18.685625,24.49125 L24.885,26.350625" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <line x1="17.0377083" y1="17.9622917" x2="25.43625" y2="9.56375" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                <path d="M5.83333333,8.02083333 C5.83333333,8.80235182 6.25026846,9.52450469 6.92708332,9.91526394 C7.60389818,10.3060232 8.43776848,10.3060232 9.11458335,9.91526394 C9.79139821,9.52450469 10.2083333,8.80235182 10.2083333,8.02083333 C10.2083333,6.81271044 9.22895622,5.83333333 8.02083333,5.83333333 C6.81271044,5.83333333 5.83333333,6.81271044 5.83333333,8.02083333" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M24.7916667,8.02083333 C24.7916667,9.22895622 25.7710438,10.2083333 26.9791667,10.2083333 C28.1872896,10.2083333 29.1666667,9.22895622 29.1666667,8.02083333 C29.1666667,6.81271044 28.1872896,5.83333333 26.9791667,5.83333333 C25.7710438,5.83333333 24.7916667,6.81271044 24.7916667,8.02083333" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M24.7916667,26.9791667 C24.7916667,28.1872896 25.7710438,29.1666667 26.9791667,29.1666667 C28.1872896,29.1666667 29.1666667,28.1872896 29.1666667,26.9791667 C29.1666667,25.7710438 28.1872896,24.7916667 26.9791667,24.7916667 C25.7710438,24.7916667 24.7916667,25.7710438 24.7916667,26.9791667" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <path d="M12.3958333,29.1666667 C16.020202,29.1666667 18.9583333,26.2285353 18.9583333,22.6041667 C18.9583333,18.979798 16.020202,16.0416667 12.3958333,16.0416667 C8.77146466,16.0416667 5.83333333,18.979798 5.83333333,22.6041667 C5.83333333,26.2285353 8.77146466,29.1666667 12.3958333,29.1666667 Z" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                            </g>
                        </g>
                    </g>
                </svg>
                : ''}
                {props.term.indexation && props.term.indexation.length > 0 ?
                <svg className="mr-xs" width="35px" height="35px" viewBox="0 0 35 35" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                        <g transform="translate(-979, -372)">
                            <g transform="translate(979, 372)">
                                <polygon points="0 0 35 0 35 35 0 35"></polygon>
                                <path d="M5.83333333,8.75 C5.83333333,7.13916948 7.13916948,5.83333333 8.75,5.83333333 L26.25,5.83333333 C27.8608305,5.83333333 29.1666667,7.13916948 29.1666667,8.75 L29.1666667,26.25 C29.1666667,27.8608305 27.8608305,29.1666667 26.25,29.1666667 L8.75,29.1666667 C7.13916948,29.1666667 5.83333333,27.8608305 5.83333333,26.25 L5.83333333,8.75 Z" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                <line x1="11.6666667" y1="5.83333333" x2="11.6666667" y2="29.1666667" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                <line x1="23.3333333" y1="5.83333333" x2="23.3333333" y2="29.1666667" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                <line x1="5.83333333" y1="11.6666667" x2="11.6666667" y2="11.6666667" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                <line x1="5.83333333" y1="23.3333333" x2="11.6666667" y2="23.3333333" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                <line x1="5.83333333" y1="17.5" x2="29.1666667" y2="17.5" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                <line x1="23.3333333" y1="11.6666667" x2="29.1666667" y2="11.6666667" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                                <line x1="23.3333333" y1="23.3333333" x2="29.1666667" y2="23.3333333" stroke="#809fa5" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                            </g>
                        </g>
                    </g>
                </svg>
                : ''}
                { props.term.show ?
                <svg className="mr-s" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                        <g transform="translate(-1645.000000, -92.000000)" stroke="#809fa5" strokeWidth="3">
                            <g transform="translate(1656.957134, 104.000000) scale(1, -1) translate(-1656.957134, -104.000000) translate(1647.000000, 94.000000)">
                                <path d="M0,0 C12.6095123,13.3333333 18.9142684,20 18.9142684,20 C18.9142684,20 12.6095123,13.3333333 0,0 Z"></path>
                                <path d="M1,0 C13.6095123,13.3333333 19.9142684,20 19.9142684,20 C19.9142684,20 13.6095123,13.3333333 1,0 Z" transform="translate(10.457134, 10.000000) scale(-1, 1) translate(-10.457134, -10.000000) "></path>
                            </g>
                        </g>
                    </g>
                </svg>
                : ''}
                </>
                : ''}
                { !props.term.show && props.term.children && props.term.children.length > 0 ?
                <svg className="mr-s" width="22px" height="12px" viewBox="0 0 22 12" version="1.1">
                    <title>ico-unfold</title>
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                        <g transform="translate(-1050, -453)" stroke="#809fa5" strokeWidth="3">
                            <polyline points="1051 454 1061 464 1070.78254 454.21746"></polyline>
                        </g>
                    </g>
                </svg>
                : ''}
            </Link>
            { props.term.show && childs && childs.length > 0 ?
                <ul className="open">
                    { childs.map(res => {
                        const newLevel = level + 1;
                        return <TermTree key={res.term_id} option={props.option} term={res} level={newLevel} updateTerm={updateSelectedTerm} resetChilds={resetChilds} showChildTerm={showChildTerm}></TermTree>
                    })}

                </ul>
            : ''}
        </li>
        </>
    )
}
