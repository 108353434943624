import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser';
import { getPage } from '../../assets/js/api';
import LayoutDeafult from "../../components/Layout/Default";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';

export default function Pagina(props){

    const [data, setData] = useState(null);

    useEffect(() => {
        let term_id = null;
        if (props.match.params.id === 'el-projecte') {
            term_id = 'ww1_1';
        } else if (props.match.params.id === 'agraiments') {
            term_id = 'ww1_2';
        } else if (props.match.params.id === 'credits') {
            term_id = 'ww1_3';
        } else if (props.match.params.id === 'contacte') {
            term_id = 'ww1_4';
        } else if (props.match.params.id === 'el-repositori') {
            term_id = 'ww1_5';
        }
        if (term_id) {
            getPage(term_id).then(res => {
                setData(res.data.result && res.data.result.length > 0 ? res.data.result[0] : null)
            });
        }
    }, [props.match.params.id]);

    return (
        data ?
        <LayoutDeafult pageMeta={{
            title: data.term
        }}>
            <Breadcrumbs currentSite={data.term}/>
            <div className="wrapper">
                <div className="columns  is-centered">
                    <div className="column pb-l  is-10-desktop is-10-tablet mt-xl has-text-centered">
                        <h1 className="mb-s">{data.term}</h1>
                    </div>
                </div>
                <div className="columns  is-centered">
                    <div className="column pb-l  is-10-desktop is-10-tablet">
                        <p className="text">{parse(data.abstract)}</p>
                    </div>
                </div>
            </div>
        </LayoutDeafult>
        : ''
    )

}
