import React, { useState } from 'react';
import { Link } from 'react-router-dom'
import { useHistory } from "react-router-dom";
import { getSearchResults } from '../../assets/js/api';
import { translate } from '../../assets/js/functions';

export default function Searcher(props){

    const [searchInput, setSearchInput]     = useState(null);
    const [terms, setTerms] = useState([]);
    const history = useHistory();

    function _handleInputChange(text){
        //setSearchInput(text.replace('\'', ''));
        if (text.length >= 3) {
            setTerms([]);
            getSearchResults(text).then(res => {
                setTerms(res ? res : null)
            });
        } else {
            setTerms([]);
        }

        setSearchInput(text);
    }

    function handleSubmit(event){
        event.preventDefault();
        if (isSearchable()) {
            history.push("/search?query="+searchInput);
        }
    }

    function isSearchable(){
        if (searchInput && searchInput.length >= 2) return true;
        return false;
    }


    return (
        <>
            <form onSubmit={handleSubmit} className="search-form home" id="search-form">
                <fieldset className="flow">
                    <div className="field">
                        <label className="label is-hidden" htmlFor="cercador">Cercador</label>
                        <div className="control">
                            <input name="query" type="search" id="cercador" className="input" onChange={res => _handleInputChange(res.target.value)} value={searchInput || ''} placeholder={props.placeholder} />
                        </div>
                    </div>
                    <div className="has-text-centered btn">
                        <button type="submit" name="enviar" className="button search" aria-label="Cercar">
                            <svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-758.000000, -529.000000)" stroke="#013F4C" strokeWidth="3">
                                        <g transform="translate(760.000000, 531.000000)">
                                            <ellipse cx="7.77029255" cy="7.34075652" rx="7.77029255" ry="7.34075652"></ellipse>
                                            <line x1="13.5598328" y1="12.805282" x2="18" y2="17" strokeLinecap="round" strokeLinejoin="round"></line>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </button>
                    </div>
                    { isSearchable() || (terms && terms.length > 0) ?
                    <div className="result">
                        { terms.map(term => {
                            return <>
                            <Link to={"/search/term/"+term.term_id}>
                            <span><strong>{term.term}</strong> - {translate('cerca en el tesaure') }</span>
                            <svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-758.000000, -529.000000)" stroke="#FFFFFF" strokeWidth="3">
                                        <g transform="translate(760.000000, 531.000000)">
                                            <ellipse cx="7.77029255" cy="7.34075652" rx="7.77029255" ry="7.34075652"></ellipse>
                                            <line x1="13.5598328" y1="12.805282" x2="18" y2="17" strokeLinecap="round" strokeLinejoin="round"></line>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                            </Link>
                            </>;
                        })}

                        { isSearchable() ?
                        <Link to={"/search?query="+searchInput}>
                            <span><strong>{searchInput}</strong> - {translate('cerca en la transcripció de les entrevistes')}</span>
                            <svg width="22px" height="21px" viewBox="0 0 22 21" version="1.1">
                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <g transform="translate(-758.000000, -529.000000)" stroke="#013F4C" strokeWidth="3">
                                        <g transform="translate(760.000000, 531.000000)">
                                            <ellipse cx="7.77029255" cy="7.34075652" rx="7.77029255" ry="7.34075652"></ellipse>
                                            <line x1="13.5598328" y1="12.805282" x2="18" y2="17" strokeLinecap="round" strokeLinejoin="round"></line>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </Link>
                        :''}
                    </div>
                    : ''}
                </fieldset>
                    </form>
        </>
    )
}
