import React, { useState, useEffect } from 'react'
import { getFreeSearch } from '../../assets/js/api';
import { translate } from '../../assets/js/functions';
import LayoutDeafult from "../../components/Layout/Default";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import SearchResult from "../../components/Search/SearchResult";

export default function Search(props){

    const title = translate('Buscador');
    const [data, setData] = useState(null);
    const [count, setCount] = useState(null);
    const param = window.location.search.split("?query=")[1];
    const breadcrumbsStatic = [
        {name: translate("Testimonis"), url: "/testimonis"}
    ];

    useEffect(() =>{
        if (param){
            getFreeSearch(param).then(res => {
                let data = (res.data.result && res.data.result.length > 0) ? res.data.result : [];
                if (data) {
                    data = data.filter((value) => {
                        return typeof value.informant !== 'undefined' && value.informant !== null && value.informant.length > 0;
                    });
                    data.sort((a, b) => {
                        const comp = a.informant[0].surname.localeCompare(b.informant[0].surname);
                        if (comp === 0) {
                            return a.informant[0].name.localeCompare(b.informant[0].name);
                        }
                        return comp;
                    });
                }
                let count = 0;
                data.map((res) => {
                    count += res.fragments.length;
                });
                setCount(count);
                setData(data);
            });
        }
    }, [param]);

    return (
        <LayoutDeafult pageMeta={{
            title: title
        }}>
            <Breadcrumbs staticData={breadcrumbsStatic} currentSite={translate('Resultats de la cerca per transcripció')+' \''+decodeURI(param)+'\''}/>
            { data ?
            <>
            <div className="wrapper">
                <div className="columns  is-centered">
                    <div className="column pb-l  is-10-desktop is-10-tablet mt-xl has-text-centered">
                        <h1 className="mb-s">{translate('Resultats de la cerca per transcripció')} &#39;{decodeURI(param)}&#39;</h1>
                        <div className="mt-l">
                            <p className="is-size-5">{count} {translate('resultats')}</p>
                        </div>
                    </div>
                </div>
            </div>
                <div className="wrapper">
                    <div className="columns is-multiline">
                    {
                        data && !!data.length ? data.map((res) => {
                            if (res.fragments && res.fragments.length > 0) {
                                return <SearchResult data={res}></SearchResult>
                            }
                            return '';
                        }): ''
                    }
                    </div>
                </div>
            </>
            :
            <div className="wrapper">
                <div className="columns mt-xl is-centered loading">
                    <svg width="50" height="50" viewBox="0 0 24 24"><path d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity=".25"/><path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z"><animateTransform attributeName="transform" type="rotate" dur="0.75s" values="0 12 12;360 12 12" repeatCount="indefinite"/></path></svg>
                </div>
            </div>
            }
        </LayoutDeafult>
    )

}
