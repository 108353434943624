import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { getCurrentLanguage } from './assets/js/functions';
import App from './views/App';
/*
import { getCurrentLanguage } from './assets/js/functions';
*/


ReactDOM.render(
  <Router basename={'/'+getCurrentLanguage()}>
    <App />
  </Router>,
  document.getElementById('root')
);
