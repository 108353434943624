import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';
import { domain, getInterview, getExcludedFragments } from '../../assets/js/api';
import { translate, informantNameDisplay, getPosterframe } from '../../assets/js/functions';
import LayoutDefault from "../../components/Layout/Default";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import VideoModal from "../../components/Modals/VideoModal";
import TermsRel from "../../components/Testimonis/TermsRel";


export default function Testimoni(props){

    const [data, setData] = useState(null);
    const [open, setOpen] = useState(false);
    const [exclude, setExclude] = useState(null);
    const breadcrumbsStatic = [
        {name: translate("Testimonis"), url: "/testimonis"}
    ];

    useEffect(() => {
        if (props.match){
            const dataId = props.match.params.id;
            if (dataId) {
                getInterview(dataId).then(res => {
                    setData(res.data.result && res.data.result.length > 0 ? res.data.result[0] : null)
                });
                getExcludedFragments(dataId).then(res => {
                    console.log(res);
                    setExclude(res);
                });
            }
        }
    }, [props.match]);

    // fragments


    return (
        data ?
            <LayoutDefault pageMeta={{
                title: informantNameDisplay(data.informant[0])
            }}>
                <Breadcrumbs staticData={breadcrumbsStatic} currentSite={informantNameDisplay(data.informant[0])}/>
                <div className="b-img">
                { data.audiovisual && data.audiovisual.length > 0 ?
                <div className="box-imatge hv-90">
                    <div>
                        <a href={domain+data.audiovisual[0].video} onClick={(event) => {event.preventDefault();setOpen(true)}} className="video-ample">
                            <img src={getPosterframe(domain+(data.audiovisual[0].video))} alt={data.title} className="w-100" loading="lazy" />
                            <div className="play">
                                <svg width="80px" height="84px" viewBox="0 0 20 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(-309.000000, -881.000000)" fill="#FFFFFF">
                                            <polygon transform="translate(319.270943, 892.898230) rotate(90.000000) translate(-319.270943, -892.898230) " points="319.270943 883.39823 330.452359 902.39823 308.089527 902.39823"></polygon>
                                        </g>
                                    </g>
                                </svg>
                                <span className="is-block is-size-7">{translate('Reproduir')}</span>
                            </div>
                        </a>
                    </div>
                </div>
                : ''}

                <div className="wrapper">
                    <div className="columns is-multiline is-block-tablet-only">
                        <div className="column pb-0 pr-0 my-xl is-6-desktop is-12-tablet">
                            <p className="mb-s has-text-weight-semibold is-uppercase">{translate('ENTREVISTA COMPLETA')}</p>
                            <h1 className="mb-l is-size-6">{informantNameDisplay(data.informant[0])}</h1>
                            <p className="is-size-10 text-columns">{parse(data.abstract)}</p>
                        </div>
                    </div>
                </div>
                </div>

                { data.audiovisual && data.audiovisual.length > 0 ?
                <>
                <TermsRel testimoni={data} audiovisual={data.audiovisual[0]}></TermsRel>
                <VideoModal
                    open={open}
                    video={domain+data.audiovisual[0].video}
                    subtitle={data.audiovisual[0].subtitles}
                    exclude={exclude}
                    toggleModal={() => {setOpen(!open)}} />
                </>
                : ''}
                </LayoutDefault>

        : ''
    )
}
