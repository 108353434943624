import React, { useState } from 'react'
import parse from 'html-react-parser';
import { domain } from '../../assets/js/api';
import { translate, getPosterframe, informantNameDisplay } from '../../assets/js/functions';
import VideoModal from "../Modals/VideoModal";

export default function SearchResultFragment(props){

    const res = props.data;
    const fragment = props.fragment;
    const [open, setOpen] = useState(false);
    const imgUrl = getPosterframe(domain+(props.data.image_url));
    const imgStyles = {
        backgroundImage: `url(${imgUrl})`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover'
    }
    return <>
    <div className="column is-12">
        <div className="box-results">
            <div className="columns is-multiline is-variable is-8">
                <div className="column is-8-deskop  is-12-touch">
                    <a href={domain+fragment.video_url} className="h-100 video" onClick={(event) => {event.preventDefault();setOpen(true)}} >
                        <div className="is-flex-desktop h-100">
                            <div className="column is-6-desktop is-12-touch p-0 m-0 media is-hidden-tablet-only" style={imgStyles}>
                                {/*<img className="w-100 h-100" src={getPosterframe(domain+(res.image_url))} alt={res.title} />*/}
                                <div className="play">
                                    <svg width="20px" height="24px" viewBox="0 0 20 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g transform="translate(-309.000000, -881.000000)" fill="#FFFFFF">
                                                <polygon transform="translate(319.270943, 892.898230) rotate(90.000000) translate(-319.270943, -892.898230) " points="319.270943 883.39823 330.452359 902.39823 308.089527 902.39823"></polygon>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>{translate('Reproduir')}</span>
                                </div>
                            </div>
                            <div className="column is-6-desktop is-12-touch p-0 m-0 media is-hidden-desktop">
                                <img className="w-100 h-100" src={getPosterframe(domain+(res.image_url))} alt={res.title} />
                                <div className="play">
                                    <svg width="20px" height="24px" viewBox="0 0 20 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                            <g transform="translate(-309.000000, -881.000000)" fill="#FFFFFF">
                                                <polygon transform="translate(319.270943, 892.898230) rotate(90.000000) translate(-319.270943, -892.898230) " points="319.270943 883.39823 330.452359 902.39823 308.089527 902.39823"></polygon>
                                            </g>
                                        </g>
                                    </svg>
                                    <span>{translate('Reproduir')}</span>
                                </div>
                            </div>
                            <div className="column is-6-desktop is-12-touch ">
                                <div className="title px-l pt-2xl flow">
                                    <h2>{res.title}</h2>
                                    {/*<p>{parse(res.abstract)}</p>*/}
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div className="column is-4-desktop is-12-touch ">
                    <div className="column txt px-l p-l flow">
                        <p>{parse(fragment.list_fragment)}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <VideoModal
        open={open}
        video={domain+fragment.video_url}
        subtitle={fragment.subtitles_url}
        name={informantNameDisplay(res.informant[0])}
        url={"/testimonis/"+JSON.parse(res.audiovisual)[0]}
        toggleModal={() => {setOpen(!open)}} />
    </>;
}
