import React, { useEffect, useState } from 'react';
import { translate, leafletIcon, termsSort, fragmentsSort } from '../../assets/js/functions';
import { Link } from 'react-router-dom'
import { getExploreMaps, getThematicIndexations, getFragmentfromIndexLocator } from '../../assets/js/api';
import LayoutDeafult from "../../components/Layout/Default";
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import Lateral from '../../components/Terms/Lateral';


export default function Map(props){

    const title = translate("Mapes")
    const [data, setData] = useState(null)
    const [current, setCurrent] = useState(null)
    let [map, setMap] = useState(null)
    const zoom = 6;
    const zoomDetail = 12;
    const target = ['40.416775', '-3.703790'];

/*
La informació del mapa procedeix dels termes dependents del tesaure de
“Entitats i escoles educatives” [on1_5]
i “Equipaments sanitaris” [on1_22].
La georeferenciació està introduïda dins del “marc espaial”

funció records ts_onomastic
sumar resultats de on1_5 i on1_22, el primer nivell, mostrar-ho a dalt, el segon a detalls

el resultat, mostrar-ho en llistat, no cal consultes següents

[
{
    camps term
    children: []
}

]

*/
    useEffect(() => {
        const script = document.createElement("script");
        script.src = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.js";
        script.async = true;
        script.onload = () => scriptLoaded();
        document.body.appendChild(script);

        const style = document.createElement("link");
        style.type = "text/css";
        style.rel = "stylesheet";
        style.href = "https://unpkg.com/leaflet@1.6.0/dist/leaflet.css";
        document.head.appendChild(style);
    }, []);

    function _updateShowGroup(res, index) {
        let newData = [...data];
        newData[index].show = !newData[index].show;
        if (newData[index].show) {
            res.mapGroup.addTo(res.map);
        } else {
            res.map.removeLayer(res.mapGroup);
        }
        setData(newData);
    }

    function scriptLoaded(){
        let L = window.L;
        map = new L.map(document.getElementById('map'));
        L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        }).addTo(map);
        map.setView(target, zoom);
        setMap(map);
        getExploreMaps(['on1_5', 'on1_22']).then(res => {
            setData(updateMap(map, res));
        });
    }

    function updateMap(map, data) {
        let L = window.L;
        data.forEach(res => {
            res.map = map;
            res.mapGroup = L.layerGroup().addTo(map);

            const icon = leafletIcon(res.pinter);

            res.childrens.forEach(point => {
                const target = L.latLng(point.location_map.lat, point.location_map.lon);
                var popup = L.popup();
                point.marker = L.marker(target, {icon: icon})
                .addTo(res.mapGroup)
                .bindPopup(popup)
                .on('click', () => {updateCurrent(point)});
                popup.setContent('<h6>'+point.term+'</h6>');
            });
        });
        return data;
    }

    function updateCurrent(elem) {
        if (elem === null) {
            map.setView(target, zoom);
            map.closePopup();
            setCurrent(null);
            return;
        }
        if (typeof elem.combina !== 'undefined' && typeof elem.fragments !== 'undefined') {
            map.flyTo(elem.marker.getLatLng(), zoomDetail);
            elem.marker.openPopup();
            setCurrent(null);
            setCurrent(elem);
            return;
        }

        getThematicIndexations(elem.term_id).then(res => {
            const result = res.data.result && res.data.result.length > 0 ? res.data.result[0] : null;
            if (result) {
                const promise = JSON.parse(result.indexation).map(
                    i => {
                        return getFragmentfromIndexLocator(JSON.stringify(i)).then(
                            interview => {
                                return interview.data.result;
                            }
                        )
                    }
                )
                Promise.all(promise).then(
                    response => {
                        let fragments = []
                        let terms = []
                        let have = [elem.term_id];
                        response.map(value => {
                            if (value[0].result && value[1].result && value[1].result.length > 0) {
                                fragments.push([value[0].result, value[1].result[0]]);
                                if (value[0].result.terms.length > 0) {
                                    value[0].result.terms.map(term => {
                                        if (!have.includes(term.term_id)) {
                                            terms.push(term);
                                            have.push(term.term_id);
                                        }
                                    })

                                }
                            }
                        });
                        elem.combina = termsSort(terms);
                        elem.fragments = fragmentsSort(fragments);
                        map.flyTo(elem.marker.getLatLng(), zoomDetail);
                        elem.marker.openPopup();
                        setCurrent(elem);
                    }
                )
            }
        });
    }

    return (
        <LayoutDeafult pageMeta={{
            title: title
        }}>
            <Breadcrumbs currentSite={title}/>

        <div className="mt-m mapa">
            <div className="columns is-multiline m-0">
                <div className="column is-6-desktop is-12-touch ml-0 pl-0 pr-0-touch mb-0 pb-0 ">
                    <div className="w-100" id="map"></div>
                </div>
                { !current ?
                <div className="column is-6-desktop  mb-0 pb-0">
                    <h1 className="mb-s">{ translate('Mapa') }</h1>
                    { data ?
                    <>
                    <p className="is-uppercase is-size-8 has-text-weight-semibold">{ translate('Escull la categoria') }</p>
                    <div className="mb-xl">
                        <ul role="list" className="mt-s">
                            { data.map((res, index) => {
                                return <>
                            <li key={'map_group_'+res.term_id}>
                                <Link to={"/search/term/"+res.term_id} className={"button button--mapa mt-s is-size-8 "+(!res.show?'blanc':res.class)} onClick={(event) => {event.preventDefault();_updateShowGroup(res, index)}}>
                                    {/*<svg className=" mr-xs" fill="#9BD5E5" width="20px" height="20px" version="1.1" x="0px" y="0px" viewBox="0 0 512 512">
                                        <path d="M256,0C150.4,0,64.5,85.9,64.5,191.5c0,34.4,16.2,77.8,49.4,132.7c27.6,45.6,61.8,90.9,91.9,130.8  c13.9,18.4,27,35.8,37.1,50.2c3,4.3,7.9,6.8,13.1,6.8c5.2,0,10.1-2.5,13.1-6.8c10.1-14.4,23.2-31.8,37.1-50.2  c30.1-39.9,64.2-85.2,91.9-130.8c33.2-54.9,49.4-98.3,49.4-132.7C447.5,85.9,361.6,0,256,0z M256,280c-48.8,0-88.5-39.7-88.5-88.5  c0-48.8,39.7-88.5,88.5-88.5c48.8,0,88.5,39.7,88.5,88.5C344.5,240.3,304.8,280,256,280z" />
                                    </svg>*/}
                                    {res.term}
                                    { res.show ?
                                    <svg className="ml-s" width="15px" height="15px" viewBox="0 0 24 24" version="1.1">
                                        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round">
                                            <g transform="translate(-1645.000000, -92.000000)" stroke="#9BD5E5" strokeWidth="3">
                                                <g transform="translate(1656.957134, 104.000000) scale(1, -1) translate(-1656.957134, -104.000000) translate(1647.000000, 94.000000)">
                                                    <path d="M0,0 C12.6095123,13.3333333 18.9142684,20 18.9142684,20 C18.9142684,20 12.6095123,13.3333333 0,0 Z"></path>
                                                    <path d="M1,0 C13.6095123,13.3333333 19.9142684,20 19.9142684,20 C19.9142684,20 13.6095123,13.3333333 1,0 Z" transform="translate(10.457134, 10.000000) scale(-1, 1) translate(-10.457134, -10.000000) "></path>
                                                </g>
                                            </g>
                                        </g>
                                    </svg>
                                    : ''}
                                </Link>
                            </li>
                            </>;
                            })}
                        </ul>
                    </div>
                    </>
                    : ''}

                    { data ?
                        data.map(res => {
                            if (!res.show) {
                                return '';
                            }
                            return <>
                        <h2 className="is-uppercase is-size-8 has-text-weight-semibold"> {res.term}</h2>
                        <ul role="list" className="mt-m tags mapa">
                            { res.childrens.map(term => {
                                return <>
                                <li key={'map_term_'+res.term_id}><Link to={"/search/term/"+term.term_id} onClick={(event) => {event.preventDefault();updateCurrent(term)}}>{term.term}</Link></li>
                            </>;
                            })}
                        </ul>
                        </>;
                        })
                    : ''}
                </div>
                :
                <Lateral term={current} backCallback={updateCurrent}></Lateral>
                }
            </div>
        </div>

        </LayoutDeafult>
    )
}
