import React, { useEffect } from 'react'
import Searcher from '../../components/Searcher/Searcher'
import { Link } from 'react-router-dom'
import { translate, getCurrentLanguage } from '../../assets/js/functions';
import LayoutHome from "../../components/Layout/Home";

export default function Home({currentPage}){

    useEffect(() => {
        currentPage && currentPage(window.location.pathname);
    }, [currentPage])

    return (
        <LayoutHome pageMeta={{
        }}>
        <div className="wrapper">
            <div className="columns">
                <div className="column is-6-fullhd is-7-widescreen is-8-desktop is-12-mobile mt-xl-desktop">
                    <Searcher placeholder={translate("Cerca un tema en el tesaure o una paraula en la transcripció de les entrevistes")+"…"}/>
                    <div className="mt-xl-desktop mt-xs-mobile mb-xl-desktop mr-xs btn">
                        <div className="is-flex-desktop w-100 mb-s">
                            <Link to="/terms/que" className="button is-size-8 is-uppercase w-100 mr-xs-desktop mb-xs-mobile">{translate('Temes')}</Link>
                            <Link to="/testimonis" className="button is-size-8 is-uppercase w-100">{translate('Testimonis')}</Link>
                        </div>
                        <div className="is-flex-desktop w-100 mb-s">
                            <Link to="/mapa" className="button is-size-8 is-uppercase w-100 mr-xs-desktop mb-xs-mobile">{translate('Mapa')}</Link>
                            <Link to="/linia-de-temps" className="button is-size-8 is-uppercase w-100">{translate('Línia de temps')}</Link>
                        </div>
                        <a href={"/cuidar/"+getCurrentLanguage()} className="button is-size-8 w-100 mr-xs">{translate('Exposició CUIDAR!')}</a>
                    </div>

                    <div className="pt-l">
                        <ul role="list" className="columns is-flex is-multiline is-justify-content-space-between">
                            <li key="project" className="pl-s pt-s">
                                <Link to={"/el-projecte"} className="is-size-9-desktop is-size-10-mobile has-text-weight-semibold has-text-white">{translate('EL PROJECTE')}</Link>
                            </li>
                            <li key="repositori" className="pl-s pt-s">
                                <Link to={"/el-repositori"} className="is-size-9-desktop is-size-10-mobile has-text-weight-semibold has-text-white">{translate('EL REPOSITORI')}</Link>
                            </li>
                            <li key="agraiments" className="pl-s pt-s">
                                <Link to={"/agraiments"} className="is-size-9-desktop is-size-10-mobile has-text-weight-semibold has-text-white">{translate('AGRAÏMENTS')}</Link>
                            </li>
                            <li key="credits" className="pl-s pt-s">
                                <Link to={"/credits"} className="is-size-9-desktop is-size-10-mobile has-text-weight-semibold has-text-white">{translate('CRÈDITS')}</Link>
                            </li>
                            <li key="contacte" className="pl-s pt-s">
                                <Link to={"/contacte"} className="is-size-9-desktop is-size-10-mobile has-text-weight-semibold has-text-white">{translate('CONTACTA AMB NOSALTRES')}</Link>
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        </LayoutHome>
    )
}
