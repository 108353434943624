import { getApiCurrentLanguage, translate } from './functions';
import axios from "axios";

//https://preservemlamemoria.coib.cat/dedalo/lib/dedalo/publication/server_api/v1/docu/ui/?code=387Yg_4$3_kj2MzzA-!Bg61_X98Zs-%C3%A7&db_name=web_coib&lang=lg-cat
export const domain     = "https://dedalo.coib.cat";
const URL_SERVER        = domain+'/dedalo/lib/dedalo/publication/server_api/v1/json';
const db_name           = 'web_coib';
//const db_name           = 'web_coib_pre';
const code              = '387Yg_4$3_kj2MzzA-!Bg61_X98Zs-ç';
//const code              = '6_GF5$_NcÑ87_as_mvb72_mptP91_gpLa!';
const records           = '/records';
const fragment_locator  = '/fragment_from_index_locator';
const language          = getApiCurrentLanguage();


export async function getVideoUrl(data){
    if(!data) return null;
    return await JSON.parse(data.indexations).map(
        val =>
        {return axios.post(
            URL_SERVER+
            fragment_locator+'?code='+code+
            '&db_name='+db_name+
            '&lang=lg-'+language+'&index_locator='+JSON.stringify(val)
        )})
}

export async function getFreeSearch(param){
    param = param.split("(")[0].trim();
    return await axios.post(
        URL_SERVER+"/free_search"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&rows_per_page=0"+
        "&q="+param+
        "&is_literal=true"+
        "&appearances_limit=5"+
        "&video_fragment=true"//+
        //"&fragment_terms=true"
    );
}

export async function getPage(term_id){
    const filter = encodeURIComponent('term_id="'+term_id+'"');
    return await axios.post(
        URL_SERVER+
        "/records"+
        "?code="+code+
        "&db_name="+db_name+
        "&table=ts_web"+
        "&lang=lg-"+language+
        "&sql_filter="+filter
    );
}



export async function getSearchVictims(text){

    var table = 'informant';
    var order = encodeURIComponent('surname asc,name asc');
    var search = encodeURIComponent("surname like '%"+text+"%' or name like '%"+text+"%'")

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+table+
        "&limit=0"+
        '&order='+order+
        '&sql_filter='+search
    );
}








export async function getSearchVictim(id){

    var table = 'informant';

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+table+
        "&section_id="+id
    );
}

export async function getSearchResults(text){

    var ar_calls = [
    {
        id: "onomastic",
        options: {
            dedalo_get: "records",
            table: "ts_onomastic",
            ar_fields: [
                "term_id",
                "term",
                "descriptor",
                "parent"
            ],
            sql_filter: "term like '%"+text.replace("'", "\\'")+"%' and indexation is not null",
            lang: "lg-"+language,
            //resolve_portal: true,
            //resolve_portals_custom: {"children": "ts_onomastic"}
        }
    },
    {
        id: "thematic",
        options: {
            dedalo_get: "records",
            table: "ts_thematic",
            ar_fields: [
                "term_id",
                "term",
                "descriptor",
                "parent"
            ],
            sql_filter: "term like '%"+text.replace("'", "\\'")+"%' and indexation is not null",
            lang: "lg-"+language,
            //resolve_portal: true,
            //resolve_portals_custom: {"children": "ts_thematic"}
        }
    },
    {
        id: "chronological",
        options: {
            dedalo_get: "records",
            table: "ts_chronological",
            ar_fields: [
                "term_id",
                "term",
                "descriptor",
                "parent"
            ],
            sql_filter: "term like '%"+text.replace("'", "\\'")+"%' and indexation is not null",
            lang: "lg-"+language,
            //resolve_portal: true,
            //resolve_portals_custom: {"children": "ts_chronological"}
        }
    }
    ];

    const params = new URLSearchParams({
        ar_calls: JSON.stringify(ar_calls)
    });

    return await axios.post(
        URL_SERVER+
        "/combi"+
        "?code="+code+
        "&db_name="+db_name+
        "&"+params+
        "&lang=lg-"+language+
        "&limit=0"
    ).then(res => {
        let result = [];
        if (res.data.result) {
            res.data.result.map(value => {
                return result = [...result, ...value.result];
            })
        }
        return result;
    });
}


export async function getExplore(){

    const ar_calls = [
        {
            "id":"onomastic",
            "options":{
                "dedalo_get":"records",
                "table":"ts_onomastic",
                "sql_filter":"parent='[\"hierarchy1_270\"]' AND term NOT LIKE \"%[%\"",
                "lang":"lg-"+language
            }
        },
        {
            "id":"thematic",
            "options":{
                "dedalo_get":"records",
                "table":"ts_thematic",
                "sql_filter":"parent='[\"hierarchy1_1\"]' AND term NOT LIKE \"%[%\"",
                "lang":"lg-"+language
            }
        }
        ];

        return await axios.post(
            URL_SERVER+
            "/combi"+
            "?code="+code+
            "&db_name="+db_name+
            "&ar_calls="+JSON.stringify(ar_calls)+
            "&lang=lg-"+language+
            "&limit=0"
        );
}

export async function getExploreTermsQue(){
    const table = "ts_thematic";

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+table+
        "&order=term asc"+
        '&sql_filter=parent%3D%22%5B%5C%22ts1_21984%5C%22%5D%22'+
        "&limit=0"
    ).then(res => {
        return res.data.result ? res.data.result : null
    }).then(res => {
        return res.map(value => {
            if (value.indexation) {
                value.indexation = JSON.parse(value.indexation);
            } else {
                value.indexation = [];
            }
            if (value.children) {
                value.children = JSON.parse(value.children);
            } else {
                value.children = [];
            }
            value.fragments = [];
            value.combina = [];
            value.show = false;
            return value;
        });
    });
}

export async function getExploreTermsQui(){
    const table = "ts_onomastic";

    const filter = [
        'parent = "[\\"on1_4\\"]"',
        //'parent = "[\\"on1_98\\"]"',
        'term_id = "on1_98"'
    ];

    const sql_filter = new URLSearchParams({
        sql_filter: filter.join(' or '),
    }).toString();

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+table+
        "&order=term asc"+
        '&'+sql_filter+
        "&limit=0"
    ).then(res => {
        return res.data.result ? res.data.result : null
    }).then(res => {
        const result = res.map(value => {
            if (value.indexation) {
                value.indexation = JSON.parse(value.indexation);
            } else {
                value.indexation = [];
            }
            if (value.children) {
                value.children = JSON.parse(value.children);
            } else {
                value.children = [];
            }
            value.fragments = [];
            value.combina = [];
            return value;
        });
        result.push({
            term: translate("Infermeres/infermers"),
            term_id: 'inf',
            table: 'informant',

            children: [1],
            indexation: [],

            fragments: [],
            combina: []
        });
        result.push({
            term: translate("Metges/metgesses"),
            term_id: 'mtg',
            table: 'informant',

            children: [1],
            indexation: [],

            fragments: [],
            combina: []
        });
        result.push({
            term: translate("Personal de gestió, administració i serveis"),
            term_id: 'adm',
            table: 'informant',

            children: [1],
            indexation: [],

            fragments: [],
            combina: []
        });
        result.push({
            term: translate("Personalitats"),
            term_id: 'pers',
            table: 'informant',

            children: [1],
            indexation: [],

            fragments: [],
            combina: []
        });
        result.sort((a, b) => {
            return a.term.localeCompare(b.term)
        });
        return result.filter(value => {
            return (
                value.term_id !== 'on1_77' &&
                value.term_id !== 'on1_76' &&
                value.term_id !== 'on1_353' &&
                value.term_id !== 'on1_98'
            );
        });
    });
}

export async function getExploreTermsOn(){
    const table = "ts_onomastic";

    const filter = [
        'parent = "[\\"on1_5\\"]"',
        'parent = "[\\"on1_22\\"]"'
    ];

    const sql_filter = new URLSearchParams({
        sql_filter: filter.join(' or '),
    }).toString();

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+table+
        "&order=term asc"+
        '&'+sql_filter+
        "&limit=0"
    ).then(res => {
        return res.data.result ? res.data.result : null
    }).then(res => {
        return res.map(value => {
            if (value.indexation) {
                value.indexation = JSON.parse(value.indexation);
            } else {
                value.indexation = [];
            }
            if (value.children) {
                value.children = JSON.parse(value.children);
            } else {
                value.children = [];
            }
            value.fragments = [];
            value.combina = [];
            return value;
        });
    });
}

export async function getExploreTermsQuant(){
    const table = "ts_chronological";

    const filter = [
        'parent="[\\"hierarchy1_251\\"]"',
    ];

    const sql_filter = new URLSearchParams({
        sql_filter: filter.join(' or '),
    }).toString();

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+table+
        "&order=time asc"+
        '&'+sql_filter+
        "&limit=0"
    ).then(res => {
        return res.data.result ? res.data.result : null
    }).then(res => {
        return res.map(value => {
            if (value.indexation) {
                value.indexation = JSON.parse(value.indexation);
            } else {
                value.indexation = [];
            }
            if (value.children) {
                value.children = JSON.parse(value.children);
            } else {
                value.children = [];
            }
            value.fragments = [];
            value.combina = [];
            return value;
        });
    });
}

export async function getExploreTermChilds(term, order = 'term'){

    if (term.term_id === 'inf') {
        return getExploreTermChildsInf(term);
    }

    if (term.term_id === 'mtg') {
        return getExploreTermChildsMtg(term);
    }

    if (term.term_id === 'adm') {
        return getExploreTermChildsAdm(term);
    }

    if (term.term_id === 'pers') {
        return getExploreTermChildsPers(term);
    }

    const sql_filter = new URLSearchParams({
        sql_filter: 'parent="[\\"'+term.term_id+'\\"]" and (indexation is not null or children is not null)',
    }).toString();

    if (order === 'time') {
        order = "time asc";
    }

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+term.table+
        "&order="+order+
        '&'+sql_filter+
        "&limit=0"
    ).then(res => {
        return res.data.result ? res.data.result : null
    }).then(res => {
        return res.map(value => {
            if (value.location_map) {
                value.location_map = JSON.parse(value.location_map);
            } else {
                value.location_map = null;
            }
            if (value.indexation) {
                value.indexation = JSON.parse(value.indexation);
            } else {
                value.indexation = [];
            }
            if (value.children) {
                value.children = JSON.parse(value.children);
            } else {
                value.children = [];
            }
            value.fragments = [];
            value.combina = [];
            value.show = false;
            return value;
        });
    });
}

export async function getExploreTermChildsInf(term){
    const sql_filter = new URLSearchParams({
        sql_filter: 'typology_data like \'%"2"%\' and indexation is not null',
        ar_fields: "section_id,indexation,name,surname"
    }).toString();

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+term.table+
        //"&order="+order+
        '&'+sql_filter+
        "&limit=0"
    ).then(res => {
        return res.data.result ? res.data.result : null
    }).then(res => {
        const result = res.map(value => {
            value.term = value.surname+', '+value.name;
            value.term_id = 'inf_'+value.section_id;
            value.location_map = null;
            if (value.indexation) {
                value.indexation = JSON.parse(value.indexation);
            } else {
                value.indexation = [];
            }
            value.children = [];
            value.fragments = [];
            value.combina = [];
            value.show = false;
            return value;
        });
        result.sort((a, b) => {
            return a.term.localeCompare(b.term)
        });
        return result;
    });
}

export async function getExploreTermChildsMtg(term){
    const sql_filter = new URLSearchParams({
        sql_filter: 'typology_data like \'%"3"%\' and indexation is not null',
        ar_fields: "section_id,indexation,name,surname"
    }).toString();

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+term.table+
        //"&order="+order+
        '&'+sql_filter+
        "&limit=0"
    ).then(res => {
        return res.data.result ? res.data.result : null
    }).then(res => {
        const result = res.map(value => {
            value.term = value.surname+', '+value.name;
            value.term_id = 'mtg_'+value.section_id;
            value.location_map = null;
            if (value.indexation) {
                value.indexation = JSON.parse(value.indexation);
            } else {
                value.indexation = [];
            }
            value.children = [];
            value.fragments = [];
            value.combina = [];
            value.show = false;
            return value;
        });
        result.sort((a, b) => {
            return a.term.localeCompare(b.term)
        });
        return result;
    });
}


export async function getExploreTermChildsAdm(term){
    const sql_filter = new URLSearchParams({
        sql_filter: 'typology_data like \'%"4"%\' and indexation is not null',
        ar_fields: "section_id,indexation,name,surname"
    }).toString();

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+term.table+
        //"&order="+order+
        '&'+sql_filter+
        "&limit=0"
    ).then(res => {
        return res.data.result ? res.data.result : null
    }).then(res => {
        const result = res.map(value => {
            value.term = value.surname+', '+value.name;
            value.term_id = 'adm_'+value.section_id;
            value.location_map = null;
            if (value.indexation) {
                value.indexation = JSON.parse(value.indexation);
            } else {
                value.indexation = [];
            }
            value.children = [];
            value.fragments = [];
            value.combina = [];
            value.show = false;
            return value;
        });
        result.sort((a, b) => {
            return a.term.localeCompare(b.term)
        });
        return result;
    });
}


export async function getExploreTermChildsPers(term){
    const sql_filter = new URLSearchParams({
        sql_filter: 'typology_data like \'%"1"%\' and indexation is not null',
        ar_fields: "section_id,indexation,name,surname"
    }).toString();

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+term.table+
        //"&order="+order+
        '&'+sql_filter+
        "&limit=0"
    ).then(res => {
        return res.data.result ? res.data.result : null
    }).then(res => {
        const result = res.map(value => {
            value.term = value.surname+', '+value.name;
            value.term_id = 'pers_'+value.section_id;
            value.location_map = null;
            if (value.indexation) {
                value.indexation = JSON.parse(value.indexation);
            } else {
                value.indexation = [];
            }
            value.children = [];
            value.fragments = [];
            value.combina = [];
            value.show = false;
            return value;
        });
        result.sort((a, b) => {
            return a.term.localeCompare(b.term)
        });
        return result;
    });
}

export async function getExploreChronologic(id){
    const table = "ts_chronological";

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+table+
        "&order=time asc"+
        '&sql_filter=parent%3D%22%5B%5C%22hierarchy1_251%5C%22%5D%22'+
        "&limit=0"
    ).then(res => {
        return res.data.result ? res.data.result : null
    }).then(res => {
        return res.map(value => {
            if (value.indexation) {
                value.indexation = JSON.parse(value.indexation);
            } else {
                value.indexation = [];
            }
            if (value.children) {
                value.children = JSON.parse(value.children);
            } else {
                value.children = [];
            }
            value.fragments = [];
            value.combina = [];
            return value;
        });
    });
}

export async function getExploreMaps(ids){
    const table = "ts_onomastic";

    const params = new URLSearchParams({
        resolve_portal: true,
        resolve_portals_custom: '{"childrens": "ts_onomastic"}',
        //resolve_dd_relations: false
    }).toString();
    const filter = ids.map(value => {
        return 'parent = "[\\"'+value+'\\"]"';
    });

    const sql_filter = new URLSearchParams({
        sql_filter: 'childrens is not null and ('+filter.join(' or ')+')',
    }).toString();

    const colors = [
        //'verd',
        //'granate',
        //'fucsia',
        //'lila',
        //'vermell',
        //'blau',
        'color1',
        'color2',
        'color3',
        'color4',
        'color5',
        'color6',
    ];
    const pointers = [
        //'#013f4c',
        //'#C92E54',
        //'#8A098A',
        //'#522EC9',
        //'#E04707',
        //'#9bd5e5',
        '#013f4c',
        '#C92E54',
        '#F7B05B',
        '#F8F991',
        '#9AE19D',
        '#D6E3F8',
    ];

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+table+
        "&order=term asc"+
        '&'+sql_filter+
        '&'+params+
        "&limit=0"
    ).then(res => {
        return res.data.result ? res.data.result : null
    }).then(res => {
        return res.map((value, index) => {
            value.class = colors[index%6];
            value.pinter = pointers[index%6];
            value.childrens = value.childrens.filter(elem => elem.location_map !== null);
            value.childrens.map(elem => {
                elem.location_map = JSON.parse(elem.location_map);
                return elem;
            });

            if (value.indexation) {
                value.indexation = JSON.parse(value.indexation);
            } else {
                value.indexation = [];
            }
            value.show = true;
            return value;
        });
    });
}






export async function getExploreChildrens(id, char = null){
        const table = id.includes("dc1_") ? "ts_chronological" : (id.includes("ts1_") ? "ts_thematic" : "ts_onomastic");

        return await axios.post(
            URL_SERVER+
            records+
            "?code="+code+
            "&db_name="+db_name+
            "&lang=lg-"+language+
            "&table="+table+
            "&order=term"+
            '&sql_filter=parent%3D%22%5B%5C%22'+id+'%5C%22%5D%22'+
            (char ? "%20AND%20term%20like%20%22"+char+"%25%22" : "")+
            "&limit=0"
        );
}

export async function getExploreTitle(term_id){
        const table = term_id.includes("dc1_") ? "ts_chronological" : (term_id.includes("ts1_") ? "ts_thematic" : "ts_onomastic");
        return await axios.post(
            URL_SERVER+
            records+
            "?code="+code+
            "&db_name="+db_name+
            "&lang=lg-"+language+
            "&ar_fields=[\"term\", \"childrens\", \"indexation\"]"+
            "&table="+table+
            "&sql_filter=term_id%3D'"+term_id+"'"+
            "&limit=0"
        );
}

export async function getTermName(term_id){
    return await axios.post(
        URL_SERVER+
        "/thesaurus_term"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&ar_term_id="+term_id
    );
}

export async function getTermsCombined(terms){
    return axios.post(
        URL_SERVER+
        "/thesaurus_term"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&ar_term_id="+terms.toString()+
        "&combine=combined"
        ).then(res => {
            return res.data.result ? res.data.result : null
        }).then(res => {
            if (res === null) {
                return res;
            }
            return res.map((value, index) => {
                let indexation = [];
                if (value.indexation) {
                    indexation = JSON.parse(value.indexation);
                }
                return indexation;
            });
        }).then(res => {
            if (res === null) {
                return res;
            }
            let result = [];

            res.forEach(value => {
                result = result.concat(value);
            });

            return result.filter((value, index) => {
                const _value = JSON.stringify(value);
                return index === result.findIndex(obj => {
                    return JSON.stringify(obj) === _value;
                });
            });
        });

}

export async function getParents(currentId){
    return await axios.post(
        URL_SERVER+
        "/thesaurus_parents"+
        "?code="+code+
        "&db_name="+db_name+
        "&ar_fields=term"+
        "&lang=lg-"+language+
        "&term_id="+currentId+
        "&order=ASC"
    );
}

export async function getInterviews(){
    const params = new URLSearchParams({
        resolve_portal: false,
        resolve_portals_custom: '{"informant":"informant", "audiovisual":"audiovisual"}',
        resolve_dd_relations: false
    }).toString();

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table=interview"+
        "&"+params+
        "&lang=lg-"+language+
        '&sql_filter=informant is not null'+
        "&limit=0"
    );
}

export async function getInterview(id){
    const params = new URLSearchParams({
        resolve_portal: false,
        resolve_portals_custom: '{"informant":"informant", "audiovisual":"audiovisual"}',
        resolve_dd_relations: false
    }).toString();

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table=interview"+
        "&section_id="+id+
        "&"+params+
        "&lang=lg-"+language+
        "&limit=0"
    );
}

export async function getInformant(id){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table=informant"+
        "&lang=lg-"+language+
        "&section_id="+id
    );
}

export async function getAudiovisual(id){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table=audiovisual"+
        "&lang=lg-"+language+
        "&section_id="+id
    );
}

export async function getThematicIndexations(id){
    if (id.includes("inf_")) {
        return await axios.post(
            URL_SERVER+
            records+
            "?code="+code+
            "&db_name="+db_name+
            "&table=informant"+
            "&section_id="+id.replace('inf_', ''),
            //"&ar_fields=indexation"+
            "&lang=lg-"+language
        ).then(res => {
            return res.map(result => {
                result.term = result.surname+', '+result.name;
                result.term_id = 'inf_'+result.section_id;
                return result;
            });
        });
    }
    if (id.includes("mtg_")) {
        return await axios.post(
            URL_SERVER+
            records+
            "?code="+code+
            "&db_name="+db_name+
            "&table=informant"+
            "&section_id="+id.replace('mtg_', ''),
            //"&ar_fields=indexation"+
            "&lang=lg-"+language
        ).then(res => {
            return res.map(result => {
                result.term = result.surname+', '+result.name;
                result.term_id = 'mtg_'+result.section_id;
                return result;
            });
        });
    }
    if (id.includes("adm_")) {
        return await axios.post(
            URL_SERVER+
            records+
            "?code="+code+
            "&db_name="+db_name+
            "&table=informant"+
            "&section_id="+id.replace('adm_', ''),
            //"&ar_fields=indexation"+
            "&lang=lg-"+language
        ).then(res => {
            return res.map(result => {
                result.term = result.surname+', '+result.name;
                result.term_id = 'adm_'+result.section_id;
                return result;
            });
        });
    }
    if (id.includes("pers_")) {
        return await axios.post(
            URL_SERVER+
            records+
            "?code="+code+
            "&db_name="+db_name+
            "&table=informant"+
            "&section_id="+id.replace('pers_', ''),
            //"&ar_fields=indexation"+
            "&lang=lg-"+language
        ).then(res => {
            return res.map(result => {
                result.term = result.surname+', '+result.name;
                result.term_id = 'pers_'+result.section_id;
                return result;
            });
        });
    }

    let table = id.includes("dc1_") ? "ts_chronological" : (id.includes("ts1_") ? "ts_thematic" : "ts_onomastic");

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table="+table+
        //"&ar_fields=indexation"+
        "&lang=lg-"+language+
        "&sql_filter=term_id%3D%22"+id+"%22"
    );
}

export async function getFragmentfromIndexLocator(index_locator){

    const ar_calls = [
        {
            "id":"fragment_from_index_locator",
            "options":{
                "dedalo_get":"fragment_from_index_locator",
                "index_locator": index_locator,
                "fragment_terms": true,
                "lang":"lg-"+language
            }
        },
        {
            "id":"interview",
            "options":{
                "dedalo_get":"records",
                "table":"interview",
                "section_id": JSON.parse(index_locator).section_top_id,
                "lang":"lg-"+language,
                "resolve_portals_custom":'{"informant":"informant"}'
            }
        }
        ];

        return await axios.post(
            URL_SERVER+ //  https://inm.render.es/dedalo/lib/dedalo/publication/server_api/v1/json
            "/combi"+
            "?code="+code+ //   inm3014rtAw_testimonios
            "&db_name="+db_name+ //   dedalo4_public
            "&ar_calls="+JSON.stringify(ar_calls)+
            "&lang=lg-"+language+
            "&limit=0"
        );

}


const uniqueArray = (objects, uniqueBy, keepFirst = true) => {
    return Array.from(
        objects.reduce((map, e) => {
            let key = uniqueBy.map(key => [e[key], typeof e[key]]).flat().join('-')
            if (keepFirst && map.has(key)) return map
            return map.set(key, e)
        }, new Map()).values()
    )
}

export async function getFragmentfromRel(rels){
    let locators = [];
    rels.forEach(rel => {
        locators = [...locators, ...rel.locators];
    });

    const promise = uniqueArray(locators, ['tag_id'], true).map(
        rel => axios.post(
            URL_SERVER+
            "/fragment_from_index_locator"+
            "?code="+code+
            "&db_name="+db_name+
            "&lang=lg-"+language+
            "&index_locator="+JSON.stringify(rel)+
            "&fragment_terms=true",
        )
    )
    return Promise.all(promise).then(
        res => res.map(val => val.data.result)
    ).then(
        res => res.filter(val => val != null)
    );
}


export async function getReelTermsByAudiovisual(avId){
    return await axios.post(
        URL_SERVER+
        "/reel_terms"+
        "?code="+code+
        "&db_name="+db_name+
        "&av_section_id="+avId+
        "&lang=lg-"+language
    );
}


export async function isChildren(parent, id){
    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&table=ts_onomastic"+
        "&ar_fields=[\"term_id\"]"+
        "&sql_filter=term_id%20%3D%22"+id+"%22%20AND%20parent%3D%22%5B%5C%22"+parent+"%5C%22%5D%22"+
        "&lang=lg-"+language
    );



    //audiovisual
        //documents
        //image
    //interview
}



export async function getFragmentsByAudiovisual(param){
    return await axios.post(
        URL_SERVER+"/free_search"+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&rows_per_page=0"+
        "&q=familia"+
        "&is_literal=true"+
        "&appearances_limit=5"+
        "&video_fragment=true"//+
        //"&fragment_terms=true"
    );
}

export async function getExcludedFragments(id){
    const table = "ts_special";

    return await axios.post(
        URL_SERVER+
        records+
        "?code="+code+
        "&db_name="+db_name+
        "&lang=lg-"+language+
        "&table="+table+
        "&section_id=1"+
        "&limit=1"
    ).then(res => {
        return res.data.result ? res.data.result[0] : null
    }).then(value => {
        value.indexation = JSON.parse(value.indexation);
        return value.indexation.filter(elem =>
            elem.section_top_id === id
        );
    }).then(res => {
        return getFragmentsFomLocators(res);
    });
}



export async function getFragmentsFomLocators(locators){
    const promise = uniqueArray(locators, ['tag_id'], true).map(
        rel => axios.post(
            URL_SERVER+
            "/fragment_from_index_locator"+
            "?code="+code+
            "&db_name="+db_name+
            "&lang=lg-"+language+
            "&index_locator="+JSON.stringify(rel)+
            "&fragment_terms=true",
        )
    )
    return Promise.all(promise).then(
        res => res.map(val => val.data.result)
    );
}