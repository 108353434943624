import React, { useEffect, useState } from 'react';
import { translate, termsSort, fragmentsSort } from '../../assets/js/functions';
import { Link } from 'react-router-dom'
import LateralMap from '../../components/Terms/LateralMap';
import LateralFragments from '../../components/Terms/LateralFragments';
import LateralTerm from '../../components/Terms/LateralTerm';
import { getTermsCombined, getFragmentfromIndexLocator } from '../../assets/js/api';

export default function Lateral(props){

    const term = props.term;
    const showMap = props.showMap;
    const [back, setBack] = useState(false);
    const [allTerms, setAllTerms] = useState(false);
    const [close, setClose] = useState(false);
    const [fragments, setFragments] = useState(null);
    const [combina, setCombina] = useState(null);
    const [filter, setFilter] = useState([]);

    useEffect(() => {
        if (props.backCallback) {
            setBack(true);
        }
        if (props.closeCallback) {
            setClose(true);
        }
    }, []);

    function updateAllTerms() {
        const newValue = !allTerms;
        setAllTerms(newValue);
        if (!newValue) {
            window.scrollTo(0, 0);
        }
    }

    function addFilter(term) {
        let newFilter = [...filter, term];
        updateFragments(newFilter);
        setFilter(newFilter);
    }

    function removeFilter(term) {
        let newFilter = filter.filter((value) => value.term_id !== term.term_id);
        updateFragments(newFilter);
        setFilter(newFilter);
    }

    function updateFragments(filter) {
        if (filter.length === 0) {
            setFragments(null)
            setCombina(null)
            return;
        }
        const termsId = [term.term_id, ...filter.map((value) => value.term_id)];
        getTermsCombined(termsId).then(res => {
            const promise = res.map(
                i => {
                    return getFragmentfromIndexLocator(JSON.stringify(i)).then(
                        interview => {
                            return interview.data.result;
                        }
                    )
                }
            )
            Promise.all(promise).then(
                response => {
                    let fragments = []
                    let terms = []
                    let have = [props.term.term_id];
                    response.map(value => {
                        if (value[0].result && value[1].result && value[1].result.length > 0) {
                            fragments.push([value[0].result, value[1].result[0]]);
                            if (value[0].result.terms.length > 0) {
                                value[0].result.terms.map(term => {
                                    if (!have.includes(term.term_id)) {
                                        terms.push(term);
                                        have.push(term.term_id);
                                    }
                                })

                            }
                        }
                    });
                    setCombina(terms.length > 0 ? termsSort(terms):null);
                    setFragments(fragments.length > 0?fragmentsSort(fragments):[]);
                }
            )
        });
    }

    return (
        <>
        { term && ((term.combina && term.combina.length > 0) || (term.fragments && term.fragments.length > 0)) ?
        <div className={back ? 'column is-6-desktop is-12-touch mb-0 pb-0':'column is-6-desktop is-12-touch content'}>
            <div className={back ? 'column is-11-fullhd is-12-desktop content':'column is-12 has-background-white'}>

            {back ?
            <Link to="" onClick={(event) => {event.preventDefault();props.backCallback(null)}} className="mb-m is-block has-text-weight-semibold is-flex is-align-items-center">
                <svg className="mr-xs" fill="#013F4C" height="15px" width="15px" version="1.1" viewBox="0 0 258 236.25" x="0px" y="0px" fillRule="evenodd" clipRule="evenodd">
                    <g>
                        <path className="fil0" d="M55 110l187 0c21,0 21,-32 0,-32l-187 0 51 -50c15,-15 -8,-38 -23,-23l-77 77c-7,6 -8,17 -1,24l78 78c15,15 37,-8 22,-23l-50 -51z"></path>
                    </g>
                </svg>
                <span>{translate('Tornar a Mapa General')}</span>
            </Link>
            : ''}
            {close ?
            <Link to="" onClick={(event) => {event.preventDefault();props.closeCallback(null)}} className="mb-m is-block has-text-weight-semibold is-flex is-align-items-center">
                <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square">
                        <g transform="translate(-1024, -125)" stroke="#013F4C" strokeWidth="2">
                            <g transform="translate(1040, 141.4167) rotate(45) translate(-1040, -141.4167)translate(1030, 131)">
                                <line x1="10" y1="0.416666667" x2="10" y2="20.4166667"></line>
                                <line x1="10" y1="0.416666667" x2="10" y2="20.4166667" transform="translate(10, 10.4167) rotate(-90) translate(-10, -10.4167)"></line>
                            </g>
                        </g>
                    </g>
                </svg>
                <span>{translate('Tancar')}</span>
            </Link>
            : ''}
            <h1 className="mb-l">{term.term}</h1>

            { showMap && term && term.location_map ?
            <LateralMap point={term.location_map}></LateralMap>
            : ''}

{ combina === null && term.combina && term.combina.length > 0 ?
<>
<div className="is-flex is-align-items-center mb-xs">
    <svg className="mr-xs" width="35px" height="35px" viewBox="0 0 35 35" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-120, -372)">
                <g transform="translate(120, 372)">
                    <polygon points="0 0 35 0 35 35 0 35"></polygon>
                    <path d="M8.649375,10.115 L10.50875,16.3114583 M18.685625,24.49125 L24.885,26.350625" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <line x1="17.0377083" y1="17.9622917" x2="25.43625" y2="9.56375" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                    <path d="M5.83333333,8.02083333 C5.83333333,8.80235182 6.25026846,9.52450469 6.92708332,9.91526394 C7.60389818,10.3060232 8.43776848,10.3060232 9.11458335,9.91526394 C9.79139821,9.52450469 10.2083333,8.80235182 10.2083333,8.02083333 C10.2083333,6.81271044 9.22895622,5.83333333 8.02083333,5.83333333 C6.81271044,5.83333333 5.83333333,6.81271044 5.83333333,8.02083333" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M24.7916667,8.02083333 C24.7916667,9.22895622 25.7710438,10.2083333 26.9791667,10.2083333 C28.1872896,10.2083333 29.1666667,9.22895622 29.1666667,8.02083333 C29.1666667,6.81271044 28.1872896,5.83333333 26.9791667,5.83333333 C25.7710438,5.83333333 24.7916667,6.81271044 24.7916667,8.02083333" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M24.7916667,26.9791667 C24.7916667,28.1872896 25.7710438,29.1666667 26.9791667,29.1666667 C28.1872896,29.1666667 29.1666667,28.1872896 29.1666667,26.9791667 C29.1666667,25.7710438 28.1872896,24.7916667 26.9791667,24.7916667 C25.7710438,24.7916667 24.7916667,25.7710438 24.7916667,26.9791667" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M12.3958333,29.1666667 C16.020202,29.1666667 18.9583333,26.2285353 18.9583333,22.6041667 C18.9583333,18.979798 16.020202,16.0416667 12.3958333,16.0416667 C8.77146466,16.0416667 5.83333333,18.979798 5.83333333,22.6041667 C5.83333333,26.2285353 8.77146466,29.1666667 12.3958333,29.1666667 Z" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </g>
            </g>
        </g>
    </svg>
    <span className="is-uppercase has-text-weight-semibold mr-xs">{translate('Combina amb')}</span>
    <span className="">{ term.combina.length } {translate('resultats')}</span>
</div>

<div className="column">
    <ul role="list" className="columns pl-2xs is-multiline is-flex tags">
    { term.combina.length > 6 ?
        <li><Link className="button has-background-primary has-text-white is-flex is-align-items-center" onClick={(event) => {event.preventDefault();updateAllTerms()}}>
            {(!allTerms)?translate('Veure tots els temes'):translate('Veure menys temes')}
            {(!allTerms)?
            <>
                <svg className="ml-xs" width="16px" height="16px" viewBox="0 0 22 12" version="1.1">
                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="round" strokeLinejoin="round"><g transform="translate(-1050, -453)" stroke="#FFFFFF" strokeWidth="3"><polyline points="1051 454 1061 464 1070.78254 454.21746"></polyline></g>
                    </g>
                </svg>
            </>
            :
            <>
                <svg className="ml-xs" width="16px" height="16px" viewBox="0 0 22 12" version="1.1">
                    <path fill="none" fillRule="evenodd" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="3" d="m1.5 6.5 10-10 9.783 9.783"/>
                </svg>
            </>
            }
        </Link></li>
        : '' }
    {
        term.combina.map(
            (res, i) => {
            return <LateralTerm term={res} addFilter={addFilter} removeFilter={removeFilter} hidden={(i > 6 && !allTerms)}></LateralTerm>
            }
        )
    }
    </ul>
</div>
</>
:''}

{ combina && combina.length > 0 ?
<>
<div className="is-flex is-align-items-center mb-xs">
    <svg className="mr-xs" width="35px" height="35px" viewBox="0 0 35 35" version="1.1">
        <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g transform="translate(-120, -372)">
                <g transform="translate(120, 372)">
                    <polygon points="0 0 35 0 35 35 0 35"></polygon>
                    <path d="M8.649375,10.115 L10.50875,16.3114583 M18.685625,24.49125 L24.885,26.350625" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <line x1="17.0377083" y1="17.9622917" x2="25.43625" y2="9.56375" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></line>
                    <path d="M5.83333333,8.02083333 C5.83333333,8.80235182 6.25026846,9.52450469 6.92708332,9.91526394 C7.60389818,10.3060232 8.43776848,10.3060232 9.11458335,9.91526394 C9.79139821,9.52450469 10.2083333,8.80235182 10.2083333,8.02083333 C10.2083333,6.81271044 9.22895622,5.83333333 8.02083333,5.83333333 C6.81271044,5.83333333 5.83333333,6.81271044 5.83333333,8.02083333" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M24.7916667,8.02083333 C24.7916667,9.22895622 25.7710438,10.2083333 26.9791667,10.2083333 C28.1872896,10.2083333 29.1666667,9.22895622 29.1666667,8.02083333 C29.1666667,6.81271044 28.1872896,5.83333333 26.9791667,5.83333333 C25.7710438,5.83333333 24.7916667,6.81271044 24.7916667,8.02083333" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M24.7916667,26.9791667 C24.7916667,28.1872896 25.7710438,29.1666667 26.9791667,29.1666667 C28.1872896,29.1666667 29.1666667,28.1872896 29.1666667,26.9791667 C29.1666667,25.7710438 28.1872896,24.7916667 26.9791667,24.7916667 C25.7710438,24.7916667 24.7916667,25.7710438 24.7916667,26.9791667" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                    <path d="M12.3958333,29.1666667 C16.020202,29.1666667 18.9583333,26.2285353 18.9583333,22.6041667 C18.9583333,18.979798 16.020202,16.0416667 12.3958333,16.0416667 C8.77146466,16.0416667 5.83333333,18.979798 5.83333333,22.6041667 C5.83333333,26.2285353 8.77146466,29.1666667 12.3958333,29.1666667 Z" stroke="#013F4C" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                </g>
            </g>
        </g>
    </svg>
    <span className="is-uppercase has-text-weight-semibold mr-xs">{translate('Combina amb')}</span>
    <span className="">{ combina.length } {translate('resultats')}</span>
</div>

<div className="column">
    <ul role="list" className="columns pl-2xs is-multiline is-flex tags">
    {
        combina.map(
            (res, i) => {
            return <LateralTerm term={res} addFilter={addFilter} removeFilter={removeFilter} hidden={(i > 6 && !allTerms)} active={filter.find(item => {return item.term_id === res.term_id})}></LateralTerm>
            }
        )
    }
        { combina.length > 6 ?
        <li><Link className="button has-background-primary has-text-white is-flex is-align-items-center" onClick={(event) => {event.preventDefault();updateAllTerms()}}>
            {(!allTerms)?translate('Veure tots els temes'):translate('Veure menys temes')}
            {(!allTerms)?
            <>
                <svg className="ml-xs" width="16px" height="16px" viewBox="0 0 22 12" version="1.1">
                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round"><g transform="translate(-1050, -453)" stroke="#FFFFFF" stroke-width="3"><polyline points="1051 454 1061 464 1070.78254 454.21746"></polyline></g>
                    </g>
                </svg>
            </>
            :
            <>
                <svg className="ml-xs" width="16px" height="16px" viewBox="0 0 22 12" version="1.1">
                    <path fill="none" fillRule="evenodd" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="m1.5 6.5 10-10 9.783 9.783"/>
                </svg>
            </>
            }
        </Link></li>
        : '' }
    </ul>
</div>
</>
:''}

{ fragments !== null ?
    <LateralFragments fragments={fragments}></LateralFragments>
:
<>
{ term.fragments && term.fragments.length > 0 ?
    <LateralFragments fragments={term.fragments}></LateralFragments>
:''}
</>
}
            </div>
        </div>
        : ''}
        </>
    )
}
