import React, { useEffect, useState } from "react";
import { Switch, Route, useHistory } from 'react-router-dom';
import { getCurrentLanguage } from "../assets/js/functions";
import '../assets/scss/app.scss';

import Home from "../views/Home/Home";
import Testimonis from "../views/Testimonis/Testimonis";
import Testimoni from "../views/Testimonis/Testimoni";
import Search from "../views/Search/Search";
import SearchTerm from "../views/Search/Term";
import Pagina from "../views/Pagines/Pagina";

import Map from "../views/Maps/Map";
import TimeLine from "../views/TimeLine/TimeLine";
import Terms from "../views/Terms/Terms";

import ReactGA from 'react-ga4';
ReactGA.initialize('G-BFVB70HK02');

const App = () => {

    const [path, setPath] = useState(null);
    const history = useHistory();
    const [language] = useState(getCurrentLanguage());

    useEffect(() => {
        setPath(history.location.pathname)
    }, [history.location.pathname, language])

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, []);

    return (
        <>
            <Switch>
                <Route exact path={'/'} render={props => <Home/>} />
                <Route exact path={'*/terms/term/:term'} render={props => <Terms {...props} key={'que'} term={props.match.params.term}/>} />
                <Route exact path={'*/terms/:id(que|qui|on|quan)'} render={props => <Terms {...props} key={props.match.params.id}/>} />
                <Route exact path={'*/terms'} render={props => <Terms {...props} key={'que'}/>} />
                <Route exact path={'*/linia-de-temps'} render={props => <TimeLine/>} />
                <Route exact path={'*/mapa'} render={props => <Map/>} />

                <Route exact path={'*/testimonis'} render={props => <Testimonis/>} />
                <Route exact path={'*/testimonis/:id'} render={props => <Testimoni {...props} key={props.match.params.id}/>} />

                <Route exact path={'*/search/term/:id'} render={props => <SearchTerm {...props} key={props.match.params.id}/>} />
                <Route exact path={'*/search/:id'} render={props => <Search {...props} key={props.match.params.id}/>} />
                <Route exact path={'*/search*'} render={props => <Search/>} />

                <Route exact path={'*/:id(el-projecte|el-repositori|agraiments|credits|contacte)'} render={props => <Pagina {...props}/>} />
                <Route exact path={'*'} render={props => <Home/>} />
            </Switch>
        </>
    );
}

export default App;
