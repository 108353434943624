import React, { useEffect, useState } from 'react';
import { translate } from '../../assets/js/functions';
import { Link } from 'react-router-dom'
import { getReelTermsByAudiovisual } from '../../assets/js/api';
import Fragments from "./Fragments";

export default function TermsRel(props) {

    const testimoni = props.testimoni;
    const [data, setData] = useState(null);

    useEffect(() => {
        if (props.audiovisual){
            getReelTermsByAudiovisual(props.audiovisual.section_id).then(res => {
                setData(res.data.result && res.data.result.length > 0 ? res.data.result : null)
            });
        }
    }, [props.audiovisual]);

    return (
        data ?
        <>
        <div className="wrapper">
            <div className="columns is-multiline is-block-tablet-only">
                <div className="column pb-0 pr-0 my-xl is-12  ">
                    <div className="my-l">
                        <p className="mb-xl mb-s has-text-weight-semibold is-uppercase">{translate('Termes utilitzats')}</p>
                        <ul role="list" className="columns pl-s is-multiline is-flex tags">
                            {
                                data.map(
                                    (res, i) => {
                                    return <>
                                        <li key={'terms_rel_'+res.term_id}><Link to={"/search/term/"+res.term_id}>{res.term}</Link></li>
                                    </>
                                    }
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <Fragments testimoni={testimoni} rel={data}></Fragments>
        </>
        : ''
    )
}
