import { parse, format } from 'date-fns'

export function getClassName(item){
    return item.props.className ? "-"+item.props.className : "";
}

export function language(){
    return window.location.search.split('id=')[1]
}

export function translate(text){
    const tranlates = require("../translates.json") ? require("../translates.json") : null;
    const lang      = getCurrentLanguage();


    switch(lang){
        case "ca":
            return tranlates[lang][text] || text;
        case "es":
            return tranlates[lang][text] || text;
        case "en":
            return tranlates[lang][text] || text;
        default:
            return text;
    }
}

export function getCurrentLocationByLanguage(lang){
    return "/"+window.location.pathname;
}

export function getCurrentLanguage(){
    const lang = window.location.pathname.split('/')[1];
    let validLang = false;
    getLanguages().map(
        res => res === lang ? validLang = true : false
    );
    if(validLang) return lang;
    return getLanguages()[0];
}

export function getLanguages(){
    return ['ca', 'es', 'en'];
}

export function getApiCurrentLanguage(){
    switch(getCurrentLanguage()){
        case 'ca': return 'cat';
        case 'es': return 'spa';
        case 'en': return 'eng';
        default: return 'spa';
    }
}

export function getLanguageName(lang){
    switch(lang){
        case 'ca': return 'Català';
        case 'es': return 'Español';
        case 'en': return 'English';
        default: return 'Català';
    }
}

export function getLanguageShortName(lang){
    switch(lang){
        case 'ca': return 'Cat';
        case 'es': return 'Cast';
        case 'en': return 'Eng';
        default: return 'Cat';
    }
}

export function languageRoute(lang, route = ''){
    if(lang) return window.location.host+"/"+lang//window.location.host+'/'+lang+route.replace('/'+getCurrentLanguage(), '');
    return window.location.host+route;
}

export function getPath(){
    const location = window.location;
    let path = location.pathname+location.search+location.hash;
    return path.replace(languageRoute(''), '');
}

export function isValidLanguage(){
    const lang = window.location.pathname.split('/')[1];
    let validLang = false;
    getLanguages().map(
        res => res === lang ? validLang = true : null
    );
    return validLang;
}

export function getUrlByLanguage(url){
    if(url.split('/')[1] === getCurrentLanguage()){
        return url;
    }
    return "/"+getCurrentLanguage()+url;
}

export function getTypeByTermId(item){
    const type = item.split("_")[0]
    switch(type){
        case "peri1":
            return "period"
        case "on1":
            return "onomastic"
        case "ts1":
            return "thematic"
        default:
            return null
    }
}

export function cleanArrayUndefined(items){
    return items.filter(function(x) {
        return x !== undefined;
   });
}


export var groupBy = function(xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

export function cleanArray(items){
    return [...new Set(items.map(e => JSON.stringify(e)))].map(e => JSON.parse(e))
}

export function getPosterframe(video_url){
    let posterframe_url = video_url
	posterframe_url = posterframe_url.replace(/\/404\//g, '/posterframe/')
	posterframe_url = posterframe_url.replace(/\.mp4/g, '.jpg')

    const ar_parts = posterframe_url.split("?")
        if (typeof ar_parts[0]!=="undefined") {
            posterframe_url = ar_parts[0]
        }

    return posterframe_url;
}

export function normalizeText(text, minus = false){
    if (minus) return text.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g,"")
    return text.normalize('NFD').replace(/[\u0300-\u036f]/g,"")
}

export function formatDate(date) {
    var string = date.split(" ");
    if (string[0].match(/00-00$/)) {
        string = string[0].split('-');
        return (string[0]);
    }
    return format(parse(string[0], 'yyyy-MM-dd', new Date()), 'dd/MM/yyyy');
}


export function informantNameDisplay(informant) {
    if (informant.name !== null && informant.surname !== null) {
        return informant.surname+', '+informant.name;
    }
    if (informant.surname !== null) {
        return informant.surname;
    }
    if (informant.name !== null) {
        return informant.name;
    }
    return '';
}

export function leafletIcon(color = '#013f4c') {
    let L = window.L;

    const markerHtmlStyles = `
        background-color: ${color};
        width: 1.4rem;
        height: 1.4rem;
        display: block;
        left: -.7rem;
        top: -.7rem;
        position: relative;
        border-radius: 2rem 2rem 0;
        transform: rotate(45deg);
        border: 1px solid #FFFFFF`

    return L.divIcon({
        className: "my-custom-pin",
        iconAnchor: [0, 24],
        labelAnchor: [-6, 0],
        popupAnchor: [0, -36],
        html: `<span style="${markerHtmlStyles}" />`
    });
}


export function termsSort(items) {
    return items.sort((a, b) => {
        return a.term.localeCompare(b.term)
    });
}

export function fragmentsSort(items) {
    return items.sort((a, b) => {
        return informantNameDisplay(a[1].informant[0]).localeCompare(informantNameDisplay(b[1].informant[0]))
    });
}